/* 
  import of dhx Suite is required.
*/

import("excel2json-wasm");
import XLSX from 'xlsx';
import ExcelJS from 'exceljs';

export const spreadsheetRaw = {
  data: [
    { cell: "a1", value: "Country" },
    { cell: "b1", value: "Product" },
    { cell: "c1", value: "Price" },
    { cell: "d1", value: "Amount" },
    { cell: "e1", value: "Total Price" },

    { cell: "a2", value: "Ecuador" },
    { cell: "b2", value: "Banana" },
    { cell: "c2", value: 6.68, css: "someclass" },
    { cell: "d2", value: 430 },
    { cell: "e2", value: 2872.4 }

    // more data
  ],
  //DHTMLX component initialization
  create: function(parent, name) {
    if(!parent) {
      throw new Error("parent is undefined");
    }
    if(parent[name] && parent[name].instance) {
      parent[name].instance.destructor();
      parent[name].instance = null;
    }
    if(!parent[name]) {
      parent[name] = {}
    }
    // eslint-disable-next-line no-undef
    parent[name].instance = new dhx.Spreadsheet("spreadsheet-container", {
      autoFormat:false
    });

    const styledData = {
      data: [],
      styles: {
        someclass: {
          background: "#F2F2F2",
          color: "#F57C00"
        }
      }
    };

    parent[name].instance.parse(styledData);
  },
  // Data manipulation
  sheet2Array: function(sheet) {
    let result = [];
    let rowNum;
    let colNum;
    let range = XLSX.utils.decode_range(sheet['!ref']);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        let encodedCell = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
        let nextCell = sheet[
          XLSX.utils.encode_cell({ r: rowNum, c: colNum })
        ];

        result.push({ 
          cell: encodedCell,
          value: typeof nextCell === 'undefined'? null: nextCell.w 
        });
      }
    }
    return result;
  },
  loadSheet: function(data, parent, { wBook='workbook', sOpt='sheetOptions', cSheet='currentSheet'} = {}) {
    const reader = new FileReader();
    reader.onload = async function (e) {
        const data = new Uint8Array(e.target.result);
        parent['exceljs'] = new ExcelJS.Workbook();
        parent[wBook] = await parent['exceljs'].xlsx.load(data, { type: 'array' });
        parent[wBook] = XLSX.read(data, { type: 'array' });
        const sheetsNames = parent[wBook].SheetNames;
        parent[sOpt].splice(0, parent[sOpt].length);
        
        for (let i = 0, n = sheetsNames.length; i < n; ++i) {
          parent[sOpt].push(
            {
              id: `${i}`,
              text: sheetsNames[i],
              value: sheetsNames[i],
            }
          )
        }
        parent[cSheet] = sheetsNames[0]; // Default sheet
    };
    reader.readAsArrayBuffer(data);
  }
}
