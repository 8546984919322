var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "min-height-17" }, [
      _vm.canChangeDirection()
        ? _c(
            "span",
            [
              _c("b-popover", {
                attrs: {
                  target: "HEADER_DIRECTION_" + _vm.params.column.colId,
                  placement: "top",
                  triggers: "hover",
                  content:
                    _vm.direction() === "h"
                      ? _vm.$t("task.row")
                      : _vm.$t("task.column"),
                },
              }),
              _c(
                "button",
                {
                  staticClass: "btn-action",
                  attrs: { id: "HEADER_DIRECTION_" + _vm.params.column.colId },
                  on: { click: _vm.toggleDirection },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon:
                        _vm.direction() === "h"
                          ? ["far", "arrows-left-right"]
                          : ["far", "arrows-up-down"],
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("span", [_vm._v("   ")]),
      _vm._v(" " + _vm._s(_vm.displayValue) + " "),
    ]),
    _c(
      "div",
      { staticClass: "mt-1 d-inline-block w-80" },
      [
        _c("b-form-select", {
          staticClass: "select-class min-width-120",
          attrs: { options: _vm.options },
          on: { change: _vm.onChange },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
    _c(
      "span",
      [
        _vm.canParent() || (_vm.direction() === "v" && _vm.value !== null)
          ? _c("b-popover", {
              attrs: {
                target: "HEADER_PARENT_" + _vm.params.column.colId,
                placement: "top",
                triggers: "hover",
                content: _vm.$t("task.select_parent"),
              },
            })
          : _vm._e(),
        _vm.canParent() || (_vm.direction() === "v" && _vm.value !== null)
          ? _c(
              "button",
              {
                staticClass: "btn-action",
                attrs: { id: "HEADER_PARENT_" + _vm.params.column.colId },
                on: { click: _vm.selectParent },
              },
              [
                _c("font-awesome-icon", {
                  class: _vm.hasParent() ? "active" : "",
                  attrs: { icon: ["far", "sitemap"] },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "span",
      [
        _vm.canOwn() && _vm.direction() === "h"
          ? _c("b-popover", {
              attrs: {
                target: "HEADER_OWNER_" + _vm.params.column.colId,
                placement: "top",
                triggers: "hover",
                content: _vm.owned()
                  ? _vm.$t("task.manual_link")
                  : _vm.$t("task.auto_link"),
              },
            })
          : _vm._e(),
        _vm.canOwn() && _vm.direction() === "h"
          ? _c(
              "button",
              {
                staticClass: "btn-action",
                attrs: { id: "HEADER_OWNER_" + _vm.params.column.colId },
                on: { click: _vm.selectOwner },
              },
              [
                _c("font-awesome-icon", {
                  class: _vm.owned() ? "active" : "",
                  attrs: { icon: ["far", "link"] },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }