var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.locationSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.locationSelectorShow,
              entityService: _vm.locationUtil,
              entity: "LOCATION",
              preselected: _vm.value ? _vm.value.uuId : "",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.locationSelectorShow = $event
              },
              cancel: _vm.locationSelectorCancel,
              ok: _vm.locationSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }