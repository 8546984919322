

<template>
  <div>
    <!-- customer selector -->
    <GenericSelectorModalForAdmin v-if="customerSelectorShow"
      :show.sync="customerSelectorShow" 
      :entityService="customerUtil"
      :preselected="value ? value.uuId : null"
      entity="CUSTOMER"
      nonAdmin
      @cancel="customerSelectorCancel"
      @ok="customerSelectorOk"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { customerUtil } from '@/views/management/script/customer';

export default Vue.extend({
  name: 'CustomerEditor',
  components: {
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  data() {
    return {
      value: '',
      customerSelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    customerSelectorOk({ details }) {
      const newCustomer = details.map(i => { return { uuId: i.uuId, name: i.name}});

      if (newCustomer.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newCustomer[0];
      this.closePicker();
    },
    customerSelectorCancel(deletedIds) {
      if (this.value?.uuId != null 
          && Array.isArray(deletedIds) 
          && deletedIds.length > 0 
          && deletedIds.includes(this.value.uuId)) {
        this.value = null;
      }
      this.closePicker();
    }
  },

  created() {
    this.customerUtil = customerUtil;
    this.value = this.params.value;
  },
  beforeDestroy() {
    this.customerUtil = null;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>