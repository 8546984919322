

<template>
  <div>
    <!-- location selector -->
    <GenericSelectorModalForAdmin v-if="locationSelectorShow"
      :show.sync="locationSelectorShow" 
      :entityService="locationUtil" 
      entity="LOCATION"
      :preselected="value ? value.uuId : ''"
      nonAdmin
      singleSelection
      @cancel="locationSelectorCancel"
      @ok="locationSelectorOk"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { locationUtil } from '@/views/management/script/location';

export default Vue.extend({
  name: 'LocationEditor',
  components: {
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  data() {
    return {
      value: '',
      locationSelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    locationSelectorOk({ details }) {
      const newLocation = details.map(i => { return { uuId: i.uuId, name: i.name}});

      if (newLocation.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newLocation[0];
      this.closePicker();
    },
    locationSelectorCancel(deletedIds) {
      const self = this;
      if (this.value && deletedIds.findIndex((l) => { return l.uuId === self.value.uuId }) !== -1) {
        this.value = null;
      } else if (deletedIds.length > 0) {
        // Even if the list didn't change, we replace it with the same values anyway
        // because it triggers an update in the parent grid. We do this because we could
        // be deleting an item that this row doesn't have, but another row might
        this.value = this.value.filter(i => !deletedIds.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name, rebate: i.rebate }});  
      }
      this.closePicker();
    },
  },

  created() {
    this.locationUtil = locationUtil;
    this.value = this.params.value;
  }, 
  beforeDestroy() {
    this.locationUtil = null;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>