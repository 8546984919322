

<template>
  <div>
    <div class="min-height-17">
      <span v-if="canChangeDirection()">
        <b-popover
          :target="`HEADER_DIRECTION_${params.column.colId}`"
          placement="top"
          triggers="hover"
          :content="direction() === 'h' ? $t('task.row') : $t('task.column')">
        </b-popover>
        <button :id="`HEADER_DIRECTION_${params.column.colId}`" class="btn-action" @click="toggleDirection"><font-awesome-icon :icon="direction() === 'h' ? ['far','arrows-left-right'] : ['far','arrows-up-down']"/></button> 
      </span>
      <span v-else>
        &nbsp;
      </span>
      {{ displayValue }}
    </div>
    <div class="mt-1 d-inline-block w-80"><b-form-select class="select-class min-width-120" v-model="value" :options="options" @change="onChange"></b-form-select></div>
    <span>
        <b-popover
          :target="`HEADER_PARENT_${params.column.colId}`"
          v-if="canParent() || (direction() === 'v' && value !== null)"
          placement="top"
          triggers="hover"
          :content="$t('task.select_parent')">
        </b-popover>
      <button :id="`HEADER_PARENT_${params.column.colId}`" v-if="canParent() || (direction() === 'v' && value !== null)" class="btn-action" @click="selectParent"><font-awesome-icon :class="hasParent() ? 'active' : ''" :icon="['far','sitemap']"/></button> 
    </span>
    <span>
      <b-popover
        :target="`HEADER_OWNER_${params.column.colId}`"
        v-if="canOwn() && direction() === 'h'" 
        placement="top"
        triggers="hover"
        :content="owned() ? $t('task.manual_link') : $t('task.auto_link')">
      </b-popover>
      <button :id="`HEADER_OWNER_${params.column.colId}`" v-if="canOwn() && direction() === 'h'" class="btn-action" @click="selectOwner"><font-awesome-icon :class="owned() ? 'active' : ''" :icon="['far','link']"/></button> 
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { fieldOptions, initFieldOptions } from '@/selectOptions';
import { getCustomFieldInfo } from '@/helpers/custom-fields'

export default Vue.extend({
  name: 'SettingsHeaderComponent',
  data() {
    return {
      value: null,
      options: fieldOptions
    }
  },
  beforeMount() {
    if (this.params !== null) {
      const column = this.params.displayName;
      const mappedValue = this.params.context.componentParent.mappedValues[column];
      this.value = mappedValue ? mappedValue.value : null;
      
      // allow overriding the options presented
      if (this.params.options) {
        this.options = this.params.options;
      }
      else {
        this.options = cloneDeep(fieldOptions)
      }
    }
    
    initFieldOptions(this);
    
    if (this.params.context.componentParent.mode === 'TASKS') {
      getCustomFieldInfo(this, this.params.context.componentParent.mode === 'TASKS' ? 'TASK' : this.params.context.componentParent.mode).then(() => {
          // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = this.options.find(v => v.value === cfield.name);
            if (!found) {
              this.options.push({ value: cfield.name, text: cfield.displayName });
            }
          }
        }
      });
    }
  },
  computed: {
    displayValue() {
      if (typeof this.value === 'undefined' ||
          this.value === null) {
        return this.value;
      }
      
      const mode = this.params.context.componentParent.mode;
      
      if (mode === "TASKS") {
        if (this.value.startsWith('summary')) {
          return this.$t('task_type.Project');
        }
        else if (this.value.startsWith('milestone')) {
          return this.$t('task_type.Milestone');
        }
        return this.$t('task_type.Task');
      }
      else if (mode === "COMPANY") {
        return this.$t('company.title');
      }
      else if (mode === "DEPARTMENT") {
        return this.$t('department.title');
      }
      else if (mode === "STAFF") {
        return this.$t('staff.title');
      }
      else if (mode === "CUSTOMER") {
        return this.$t('customer.title');
      }
      else if (mode === "PROJECT") {
        return this.$t('project.title');
      }
      else if (mode === "STAGE") {
        return this.$t('stage.title');
      }
      else if (mode === "TASK_TEMPLATE") {
        return this.$t('template.title');
      }
      else if (mode === "SKILL") {
        return this.$t('skill.title');
      }
      else if (mode === "RESOURCE") {
        return this.$t('resource.title');
      }
      else if (mode === "LOCATION") {
        return this.$t('location.title');
      }
      else if (mode === "REBATE") {
        return this.$t('rebate.title');
      }
      return '';
    },
    label() {
      return this.params.displayName;
    }
  },
  methods: {
    canParent() {
      return this.params.context.componentParent.canSelectParent(this.params.displayName) && (this.value === 'summary_name' || this.value === 'milestone_name' || this.value === 'name');
    },
    canOwn() {
      return this.params.options === null && this.params.context.componentParent.canSelectOwner(this.params.displayName, this.value) && (this.value && this.value !== 'summary_name' && this.value !== 'milestone_name' && this.value !== 'name');
    },
    owned() {
      return this.params.context.componentParent.getOwner(this.params.displayName);
    },
    direction() {
      return this.params.context.componentParent.getDirection(this.params.displayName);
    },
    canChangeDirection() {
      return this.params.options === null && this.value !== null && this.value !== 'summary_name' && this.value !== 'milestone_name' && this.value !== 'name';
    },
    hasParent() {
      return this.params.context.componentParent.getParent(this.params.displayName);
    },
    onChange() {
      const column = this.params.displayName;
      const mappedValue = this.params.context.componentParent.mappedValues[column];
      const prevValue = mappedValue ? mappedValue.value : null;
      this.params.context.componentParent.setMappedValue(column, this.value);
      this.params.context.componentParent.updateSettings(this.params.displayName, this.value, prevValue, this.direction(), this.direction());
      this.params.context.componentParent.gridApi.redrawRows([]);
      
      if (this.direction() === 'v' &&
          !this.canChangeDirection()) {
        this.toggleDirection();
      }
    },
    selectParent() {
      this.params.context.componentParent.updateParent(this.params.displayName, this.value);
    },
    selectOwner() {
      this.params.context.componentParent.updateOwner(this.params.displayName, this.value);
    },
    toggleDirection() {
      const prevDirection = this.direction();
      this.params.context.componentParent.toggleDirection(this.params.displayName);
      this.params.context.componentParent.updateSettings(this.params.displayName, this.value, this.value, this.direction(), prevDirection);
    }
  }
})
</script>

<style lang="scss" scoped>
.w-80 {
  width: 80%;
}

.active {
  color: var(--projectal-orange);
}

.min-width-120 {
  min-width: 120px;
}

.min-height-17 {
  min-height: 17px;
}
</style>
