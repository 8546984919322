var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.customerSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.customerSelectorShow,
              entityService: _vm.customerUtil,
              preselected: _vm.value ? _vm.value.uuId : null,
              entity: "CUSTOMER",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.customerSelectorShow = $event
              },
              cancel: _vm.customerSelectorCancel,
              ok: _vm.customerSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }