

<template>
  <div>
    <!-- project selector -->
    <GenericSelectorModalForAdmin v-if="projectSelectorShow"
      :show.sync="projectSelectorShow" 
      :entityService="projectUtil" 
      entity="PROJECT"
      nonAdmin
      singleSelection
      :preselected="value ? value.uuId : null"
      @cancel="projectSelectorCancel"
      @ok="projectSelectorOk"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { projectUtil } from '@/views/management/script/project';

export default Vue.extend({
  name: 'ProjectEditor',
  components: {
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  data() {
    return {
      value: '',
      projectSelectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    projectSelectorOk({ details }) {
      const newProject = details.map(i => { return { uuId: i.uuId, name: i.name, stages: i.stageList.map(s => { return { value: s.name, text: s.name }}), autoScheduling: i.autoScheduling}});

      if (newProject.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newProject[0];
      this.closePicker();
    },
    projectSelectorCancel(deletedIds) {
      if (this.value?.uuId != null 
          && Array.isArray(deletedIds) 
          && deletedIds.length > 0 
          && deletedIds.includes(this.value.uuId)) {
        this.value = null;
      }
      this.closePicker();
    }
  },

  created() {
    this.projectUtil = projectUtil;
    this.value = this.params.value;
  },
  beforeDestroy() {
    this.projectUtil = null;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>