<template>
  <b-modal
    header-bg-variant="light"
    header-text-variant="dark"
    no-close-on-backdrop
    
    :title="title"
    :visible="show"
    :ok-title="labelOk"
    @ok="modalOk"
    @hidden="modalOk"
    content-class="shadow"
  >
    <div class="d-block">{{ msg }}</div>

    <div slot="modal-footer" class="w-100">
      <button type="button" @click="modalOk" class="btn mr-1 btn-primary">Ok</button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "GanttErrorDialog",
  props: {
    title: {
      type: String,
      default: "Error Alert"
    },
    labelOk: {
      type: String,
      default: "Ok"
    },
    show: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: ""
    }
  },
  methods: {
    modalOk() {
      this.$emit("modal-ok");
    }
  }
};
</script>
