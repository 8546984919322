var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.title,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "button",
                {
                  staticClass: "del-button btn-action mb-2",
                  attrs: { id: "IMPORT_DOC_CLEAR_ALL" },
                  on: { click: _vm.clearAll },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "trash-can"] },
                  }),
                ],
                1
              ),
              _c(
                "b-popover",
                {
                  attrs: {
                    target: "IMPORT_DOC_CLEAR_ALL",
                    triggers: "hover",
                    placement: "top",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("button.clear_all")) + " ")]
              ),
            ],
            1
          ),
          _c("label", [_vm._v(_vm._s(_vm.$t("task.import_settings.field")))]),
          _c(
            "b-input-group",
            { attrs: { prepend: _vm.displayValue } },
            [
              _c("b-form-select", {
                attrs: { options: _vm.options },
                on: { change: _vm.onValueChange },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
          _vm.value !== null &&
          typeof _vm.settingsData[_vm.value] !== "undefined"
            ? _c("label", { staticClass: "mt-4" }, [
                _vm._v(_vm._s(_vm.$t("task.import_settings.keywords"))),
              ])
            : _vm._e(),
          _vm.value !== null &&
          typeof _vm.settingsData[_vm.value] !== "undefined"
            ? _c(
                "button",
                {
                  staticClass: "btn-action",
                  attrs: { id: "ADD_" + _vm.id },
                  on: { click: _vm.add },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } }),
                  _c("b-popover", {
                    attrs: {
                      target: "ADD_" + _vm.id,
                      placement: "top",
                      triggers: "hover",
                      content: _vm.$t("button.add"),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value !== null &&
          typeof _vm.settingsData[_vm.value] !== "undefined" &&
          _vm.settingsData[_vm.value] !== null
            ? _c("BadgeGroup", {
                staticClass: "mb-3",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var item = ref.item
                        var index = ref.index
                        return [
                          _c("Badge", {
                            key: index,
                            attrs: {
                              text: item.name,
                              attribute: " (" + item.weight + ")",
                              icon:
                                item.direction === "v"
                                  ? "arrows-up-down"
                                  : "arrows-left-right",
                              variant: "white",
                              pillable:
                                typeof item !== "undefined" && !!item.pillable,
                            },
                            on: {
                              badgeRemove: function ($event) {
                                return _vm.remove(index)
                              },
                              badgeClick: function ($event) {
                                return _vm.edit(index)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1381925329
                ),
                model: {
                  value: _vm.settingsData[_vm.value],
                  callback: function ($$v) {
                    _vm.$set(_vm.settingsData, _vm.value, $$v)
                  },
                  expression: "settingsData[value]",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.import_settings.add"),
            size: "sm",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.editOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled:
                            _vm.editVal.name === "" ||
                            _vm.editVal.name === null ||
                            _vm.editVal === null,
                          size: "sm",
                          variant: "success",
                        },
                        on: { click: _vm.editOk },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.editShow,
            callback: function ($$v) {
              _vm.editShow = $$v
            },
            expression: "editShow",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("task.import_settings.value"),
                "label-for": "axestitle",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text", trim: "" },
                    model: {
                      value: _vm.editVal.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVal, "name", $$v)
                      },
                      expression: "editVal.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("task.import_settings.weight"),
                "label-for": "weight",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "number", min: "0", trim: "" },
                    model: {
                      value: _vm.editVal.weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVal, "weight", $$v)
                      },
                      expression: "editVal.weight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value !== "summary_name" &&
          _vm.value !== "milestone_name" &&
          _vm.value !== "name"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("task.import_settings.direction"),
                    "label-for": "direction",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.directionOptions },
                        model: {
                          value: _vm.editVal.direction,
                          callback: function ($$v) {
                            _vm.$set(_vm.editVal, "direction", $$v)
                          },
                          expression: "editVal.direction",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value !== "summary_name" &&
          _vm.value !== "milestone_name" &&
          _vm.value !== "name"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("task.import_settings.owner"),
                    "label-for": "owner",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { type: "text", trim: "" },
                        model: {
                          value: _vm.editVal.owner,
                          callback: function ($$v) {
                            _vm.$set(_vm.editVal, "owner", $$v)
                          },
                          expression: "editVal.owner",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editVal.direction === "v" ||
          _vm.value === "summary_name" ||
          _vm.value === "milestone_name" ||
          _vm.value === "name"
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("task.import_settings.parent"),
                    "label-for": "parent",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { type: "text", trim: "" },
                        model: {
                          value: _vm.editVal.parent,
                          callback: function ($$v) {
                            _vm.$set(_vm.editVal, "parent", $$v)
                          },
                          expression: "editVal.parent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.deleteMessage) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }