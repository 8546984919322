<template>
  <span v-if="type === 'text'">{{ label }}</span>
  <img v-else-if="type === 'img'" class="img-contain" :src="value"/>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'ImgOrTextCellRenderer',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    this.prepareValue(this.params);
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    label() {
      return this.value != null? this.value: '';
    },
    type() {
      if (this.value !== null && this.value.startsWith('data:image/')) {
        return 'img';
      }
      return 'text';
    }
  },
  methods: {
    prepareValue(params) {
      if (params == null || params.value == null) {
        return;
      }
      if (Array.isArray(params.value)) {
        this.value = params.value.join(', ');
        return;
      }
      this.value = params.value;
    }
  }
})
</script>
<style>
.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
