

<template>
  <div>
    <span>{{ label }}</span>
    <template v-if="hasError">
      <font-awesome-icon :id="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`" class="ml-1"  style="color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>
      <b-popover
        :target="`CELL_ERROR_${params.eGridCell.getAttribute('comp-id')}`"
        placement="top"
        triggers="hover"
        :content="$t(params.errorMessage)">
      </b-popover>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ObjectCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    this.value = this.params && this.params.value && this.params.labelField ? this.params.value[this.params.labelField] : null;
  },
  computed: {
    label() {
      return this.value;
    },
    hasError() {
      return (!this.params.value && this.params.required) || (this.params.value && (this.params.required && !this.params.value[this.params.requiredField]));
    }
  }
})
</script>
<style lang="scss" scoped>

</style>