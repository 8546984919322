<template>
  <div :id="id" style="width: 100%;">
  
    <template v-if="enableDelete">
      <span id="TASK_TREE_BTN_DELETE">
        <button :disabled="selected.length === 0" class="btn-action mb-2" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></button>
      </span>
      <b-popover target="TASK_TREE_BTN_DELETE" triggers="hover" placement="top">
        {{ $t('button.delete') }}
      </b-popover>
    </template>
  
    <template v-if="enableDelete">
      <span id="TASK_TREE_BTN_CLEAR">
        <button :disabled="disableClearFilter" class="btn-action mb-2" @click="clearFilters"><font-awesome-icon :icon="['far','filter-circle-xmark']"/></button>
      </span>
      <b-popover target="TASK_TREE_BTN_CLEAR" triggers="hover" placement="top">
        {{ $t('button.clear_filters') }}
      </b-popover>
    </template>
  
    <template v-if="existingData !== null">
      <span id="TASK_TREE_BTN_EXIST">
        <button class="btn-action mb-2" @click="checkExists"><font-awesome-icon :icon="['far','arrow-right-arrow-left']"/></button>
      </span>
      <b-popover target="TASK_TREE_BTN_EXIST" triggers="hover" placement="top">
        {{ $t('button.exist') }}
      </b-popover>
    </template>
  
    <span class="total-tasks">
      {{ $t(projectId !== null ? 'task.total' : 'task.total_values', [totalTasks]) }}
    </span>
  
    <ag-grid-vue :style="`width: 100%;height: ${height}`" class="ag-theme-balham" id="preview-grid"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        animateRows
        :autoGroupColumnDef="autoGroupColumnDef"
        :columnDefs="columnDefs"
        :context="context"
        :defaultColDef="defaultColDef"
        :overlayNoRowsTemplate="overlayNoRowsTemplate"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        groupDefaultExpanded
        :rowData="rowData"
        :rowSelection="multiple? 'multiple':'single'"
        rowMultiSelectWithClick
        :sideBar="false"
        suppressDragLeaveHidesColumns
        suppressCellFocus
        suppressContextMenu=true
        suppressMultiSort
        singleClickEdit
        treeData
        >
    </ag-grid-vue>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmDeleteOk"
        >
      <div class="d-block">
        {{ deleteMessage }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <TaskDateTimeDurationCalculation :show.sync="durationCalculationShow" 
      :taskName="durationCalculation.taskName"
      :defaultActionForNonWorkPrompt="durationCalculation.defaultActionForNonWorkPrompt"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      showApplyAllCheckbox
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projectScheduleFromStart"
      :taskAutoScheduleMode="durationCalculation.taskAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :projectStartDateStr="durationCalculation.projectStartDateStr"
      :projectCloseDateStr="durationCalculation.projectCloseDateStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @skip="durationCalculationOk({ skip: true })"
      @cancel="durationCalculationCancel"
      @calendarChange="durationCalculationCalendarChange"
    />
  </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom, getStaffEmailInUse, validateEmail, checkRequiredField, getTagInUse } from '@/helpers';
import { customFieldValidateAndType } from'@/helpers/custom-fields'
import { DEFAULT_CALENDAR, TRIGGERS, convertDisplayToDuration, analyzeDurationAUM, convertDurationToDisplay, extractDurationConversionOpts } from '@/helpers/task-duration-process';
import DurationEditor from '@/components/Aggrid/CellEditor/Duration';
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime';
import CostEditor from '@/components/Aggrid/CellEditor/Cost';
import ConstraintEditor from '@/components/Aggrid/CellEditor/Constraint';
import ConstraintCellRenderer from '@/components/Aggrid/CellRenderer/Constraint';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import PercentageEditor from '@/components/Aggrid/CellEditor/Percentage';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric';
import ListEditor from '@/components/Aggrid/CellEditor/List';
import MultilineEditor from '@/components/Aggrid/CellEditor/Multiline';
import StaffEditor from '@/components/Aggrid/CellEditor/Staff';
import StageEditor from '@/components/Aggrid/CellEditor/Stage';
import StagesEditor from '@/components/Aggrid/CellEditor/Stages';
import RebateEditor from '@/components/Aggrid/CellEditor/Rebate';
import RebatesEditor from '@/components/Aggrid/CellEditor/Rebates'
import ResourceEditor from '@/components/Aggrid/CellEditor/Resources';
import TaskTemplateEditor from '@/components/Aggrid/CellEditor/TaskTemplate';
import TaskTemplateRenderer from '@/components/Aggrid/CellRenderer/TaskTemplate';
import SkillEditor from '@/components/Aggrid/CellEditor/Skills';
import StringEditor from '@/components/Aggrid/CellEditor/String';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import ErrorIconCellRenderer from '@/components/Aggrid/CellRenderer/ErrorIcon';
import RebateCellRenderer from '@/components/Aggrid/CellRenderer/Rebate';
import PriorityCellRenderer from '@/components/Aggrid/CellRenderer/Priority';
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum';
import ObjectCellRenderer from '@/components/Aggrid/CellRenderer/Object';
import CompanyEditor from '@/components/Aggrid/CellEditor/Company';
import CustomerEditor from '@/components/Aggrid/CellEditor/Customer';
import LocationEditor from '@/components/Aggrid/CellEditor/Location';
import DepartmentEditor from '@/components/Aggrid/CellEditor/Department';
import ArrayCellRenderer from '@/components/Aggrid/CellRenderer/Array';
import TaskConstraintCellRenderer from '@/components/Aggrid/CellRenderer/TaskConstraint';
import TaskAutoSchedulingCellRenderer from '@/components/Aggrid/CellRenderer/TaskAutoScheduling';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import TaskStaffCellRenderer from '@/components/Aggrid/CellRenderer/TaskStaff';
import TaskSkillCellRenderer from '@/components/Aggrid/CellRenderer/TaskSkill';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import ImageCellRenderer from '@/components/Aggrid/CellRenderer/Image';
import ImageEditor from '@/components/Aggrid/CellEditor/Image';
import ProjectEditor from '@/components/Aggrid/CellEditor/Project';
import { payFrequencies, countryCodes } from '@/selectOptions';
import { taskService, projectService, calendarService } from '@/services';
import currencies from '@/views/management/script/currencies';

/**
 * RegExp to test a string for a ISO 8601 Date spec
 *  YYYY
 *  YYYY-MM
 *  YYYY-MM-DD
 *  YYYY-MM-DDThh:mmTZD
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
var ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i

export default {
  name: 'TaskTree',
  components: {
    'ag-grid-vue': AgGridVue,
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'constraintCellRenderer': ConstraintCellRenderer,
    'durationEditor': DurationEditor,
    'dateTimeEditor': DateTimeEditor,
    'percentageCellRenderer': PercentageCellRenderer,
    'errorIconCellRenderer': ErrorIconCellRenderer,
    'percentageEditor': PercentageEditor,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'costEditor': CostEditor,
    'listEditor': ListEditor,
    'multilineEditor': MultilineEditor,
    'staffEditor': StaffEditor,
    'stageEditor': StageEditor,
    'stagesEditor': StagesEditor,
    'rebateEditor': RebateEditor,
    'rebatesEditor': RebatesEditor,
    'resourceEditor': ResourceEditor,
    'taskTemplateEditor': TaskTemplateEditor,
    'taskTemplateRenderer': TaskTemplateRenderer,
    'skillEditor': SkillEditor,
    'stringEditor': StringEditor,
    'priorityCellRenderer': PriorityCellRenderer,
    'taskConstraintCellRenderer': TaskConstraintCellRenderer,
    'taskAutoSchedulingCellRenderer': TaskAutoSchedulingCellRenderer,
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'numericEditor': NumericEditor,
    'enumCellRenderer': EnumCellRenderer,
    'objectCellRenderer': ObjectCellRenderer,
    'companyEditor': CompanyEditor,
    'customerEditor': CustomerEditor,
    'locationEditor': LocationEditor,
    'departmentEditor': DepartmentEditor,
    'arrayCellRenderer': ArrayCellRenderer,
    'rebateCellRenderer': RebateCellRenderer,
    'costCellRenderer': CostCellRenderer,
    'constraintEditor': ConstraintEditor,
    'taskStaffCellRenderer': TaskStaffCellRenderer,
    'taskSkillCellRenderer': TaskSkillCellRenderer,
    'taskResourceCellRenderer': TaskResourceCellRenderer,
    'imageCellRenderer': ImageCellRenderer,
    'imageEditor': ImageEditor,
    'projectEditor': ProjectEditor
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    taskData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Tasks'
    },
    preview: {
      type: Boolean,
      default: false
    },
    selectedItem: {
      type: String,
      default: null
    },
    staffs: {
      type: Object,
      default: null
    },
    skills: {
      type: Object,
      default: null
    },
    resources: {
      type: Object,
      default: null
    },
    enableDelete: {
      type: Boolean,
      default: false
    },
    noEdit: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '500px'
    },
    projectId: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'TASKS'
    },
    properties: { 
      type: Array, 
      default: null 
    },          // Properties the user can choose
    requiredFields: { 
      type: Array, 
      default: () => [] 
    },
    existingData: {
      type: Array,
      default: null
    },
    customFields: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      id: `TASK_TREE_${strRandom(5)}`,
      gridOptions: null,
      gridApi: null,
      autoGroupColumnDef: null,
      columnApi: null,
      context: null,
      columnDefs: null,
      defaultColDef: null,
      rowData: null,
      selected: [],
      editing: false,

      permissionsTemplate: [],
      state: {
        dataUpdating: false
      },
      
      taskMap: {},
      optionConstraint: [],
      optionPriority: [],
      optionStages: [],
      optionComplexity: [],
      optionCurrency: [],
      skillLevelNames: [],
      constraintMap: {},
      priorityMap: {},
      rebates: {},
      typeOptions: () => { return []; },
      disableClearFilter: true,
      confirmDeleteShow: false,
      currencyCode: null,
      scheduleMode: null,
      
      durationCalculationShow: false, 
      durationCalculationTasks: [],
      durationCalculationPendingUpdateTasks: [],
      durationCalculation: {
        taskName: null
        , trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projectScheduleFromStart: true
        , taskAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , defaultActionForNonWorkPrompt: null
        , skipOutOfProjectDateCheck: false
        , projectStartDateStr: null
        , projectCloseDateStr: null
        , oldConstraintType: null
        , oldConstraintDateStr: null
      }
      , showingExists: false
      , durationConversionOpts: {}
    }
  },
  beforeMount() {
    this.$store.dispatch('data/enumList').then(response => {
      if (response.jobCase != null && response[response.jobCase] != null) {
        const propertyList = response[response.jobCase]
        if (propertyList != null) {
          if (propertyList.GanttPriorityEnum != null) {
            const obj = propertyList.GanttPriorityEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] < 0) {
                continue
              }
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.optionPriority.splice(0, this.optionPriority.length, ...list)
          }
          if (propertyList.GanttComplexityEnum != null) {
            const obj = propertyList.GanttComplexityEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] < 0) {
                continue
              }
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.optionComplexity.splice(0, this.optionComplexity.length, ...list)
          }
          if (propertyList.CurrencyEnum != null) {
            const obj = propertyList.CurrencyEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] < 0) {
                continue
              }
              const found = currencies.find(i => i.code == c)
              const text = found != null && found.name != null? `${c} (${found.name})` : c
              list.push({ value: c, text, num: obj[c] })
            }
            this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            if (list.length > 0) {
              this.currencyCode = list[0].value
            }
          }
          if (propertyList.SkillLevelEnum != null) {
            const obj = propertyList.SkillLevelEnum
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] < 0) {
                continue
              }
              list.push({ value: c, text: c, num: obj[c] })
            }
            this.skillLevelNames.splice(0, this.skillLevelNames.length, ...list.map(i => i.value))
          }
        } 
      }
    }).catch(e => {
      this.httpAjaxError(e);
    });

    const self = this;
    this.gridOptions = {
      onFilterChanged: function(event) {
        self.disableClearFilter = event.api.filterManager.isAnyFilterPresent() === false;
      },
      suppressRowClickSelection: true,
      onCellValueChanged: function(event) {
        const colId = event.column.colId;
        const rowIndex = event.rowIndex;
        const newValue = event.newValue;
        const oldValue = event.oldValue;
        self.onValueChanged(colId, rowIndex, event.api, newValue, oldValue);
      },
      onCellEditingStarted: function() {
        self.editing = true;
      },
      onCellEditingStopped: function() {
        setTimeout(() => {
          self.editing = false;
        }, 500);
      },
      onFirstDataRendered: function() {
        if (self.mode === 'TASKS') {
          let trigger = TRIGGERS.START_DATE;
          
          const projectScheduleFromStart = self.scheduleMode != null? self.scheduleMode == 'ASAP' : true;
          const dCalculationTasks = self.durationCalculationTasks;
          dCalculationTasks.splice(0, dCalculationTasks.length);
    
          const pendingUpdateTasks = self.durationCalculationPendingUpdateTasks;
          pendingUpdateTasks.splice(0, pendingUpdateTasks.length);
          self.gridApi.forEachNode(node => {
            // calculate duration based upon start and end date
            if (node.data &&
                !node.data.duration &&
                node.data.startdate &&
                node.data.enddate) {  
              const nodeData = node.data;
              let newDateStr = null;
              let newTimeStr = null;
              let newConstraintType = null;
              let newConstraintDateStr = null;
              let newAutoScheduling = null;
              const newDateTime = nodeData.startdate != null? moment.utc(nodeData.startdate) : null;
              if (newDateTime != null) {
                newDateStr = newDateTime.format('YYYY-MM-DD');
                newTimeStr = newDateTime.format('HH:mm');
              }
              const startDateTime = nodeData.startdate != null? moment.utc(nodeData.startdate) : null;
              const closeDateTime = nodeData.enddate != null? moment.utc(nodeData.enddate) : null;
              const constraintDateTime = nodeData.constraint && nodeData.constraint.time != null? moment.utc(nodeData.constraint.time) : null;
              dCalculationTasks.push({
                uuId: nodeData.id
                , name: nodeData.name
                , startDateStr: startDateTime != null? startDateTime.format('YYYY-MM-DD') : null
                , startTimeStr: startDateTime != null? startDateTime.format('HH:mm') : null
                , closeDateStr: closeDateTime != null? closeDateTime.format('YYYY-MM-DD') : null
                , closeTimeStr: closeDateTime != null? closeDateTime.format('HH:mm') : null
                , durationDisplay: nodeData.duration
                , constraintType: nodeData.constraint && nodeData.constraint.type != null? nodeData.constraint.type : null
                , constraintDateStr: constraintDateTime != null? constraintDateTime.format('YYYY-MM-DD') : null
                , newDateStr, newTimeStr
                , trigger: trigger
                , taskAutoScheduleMode: nodeData.autoScheduling != null? nodeData.autoScheduling : true
                , projectScheduleFromStart
                , lockDuration: nodeData.lockDuration != null? nodeData.lockDuration : false
                , projectStartDateStr: self.project != null? self.project.startDateStr : null
                , projectCloseDateStr: self.project != null? self.project.closeDateStr : null
                , newConstraintType, newConstraintDateStr
                , newAutoScheduling
              });
            }
          });
          self.initDurationCalculation();
        }
      },
      onSelectionChanged: debounce((event) => {
        if(!this.state.dataUpdating) {
          this.selected = event.api.getSelectedNodes().map(i => {
            if (typeof i.data !== 'undefined') { 
              return { id: i.data.id, column: i.data.column, name: i.data.name, parent: i.data.parent }; 
            }
          });
          this.$emit('selected', this.selected);
        }
      }, 300),
      onColumnVisible: function(event) {
        if (event.columns[0].colId === 'constraintType') {
          self.gridOptions.columnApi.setColumnVisible('constraintTime', event.columns[0].visible);
        }
        else if (event.columns[0].colId === 'constraintTime') {
          self.gridOptions.columnApi.setColumnVisible('constraintType', event.columns[0].visible);
        }
        self.$emit('columnChange', self.gridOptions.columnApi.getAllDisplayedColumns()); 
        event.api.sizeColumnsToFit();
      },
      onRowSelected: function(/** event */) {
        // var name = event.data.name;
      },
      getDataPath: function(data) {
        if (typeof data !== 'undefined') {
          const path = [`${data.id}`];
          var parent = data.parent;
          while (parent !== null && typeof parent !== 'undefined') {
            path.unshift(`${parent}`);
            parent = typeof self.taskMap[parent] !== 'undefined' ? self.taskMap[parent].parent : null;
            if (path.includes(parent)) {
              // we have already added this parent
              break;
            }
          }
          return path;
        }
        return [];
      },
      getRowId: function(params) {
        if (typeof params.data !== 'undefined') {
          return `${params.data.id}`;
        }
        return "";
      }
    };
    
    const editable = (params) => { 
    if (self.mode === 'COMPANY' &&
            params.data !== 'undefined' &&
            params.data.type === 'Primary') {
          return false;   
        }
      return !(self.noEdit) && !(self.editing); 
    };
    
    this.autoGroupColumnDef = {
      headerName: this.title,
      minWidth: 200,
      headerCheckboxSelection: this.preview,
      headerCheckboxSelectionFilteredOnly: true,
      cellRendererParams: {
        innerRenderer: 'errorIconCellRenderer',
        maxLength: 200,
        innerRendererParams: {
          existsIcon: true
        },
        message: 'task.error.text_length',
        suppressCount: true,
        checkbox: (params) => {
          if (typeof params.data !== 'undefined') {
            if (typeof params.data.checkbox !== 'undefined') {
              return params.data.checkbox;
            }
          }
          return true;
        }
      },
      valueGetter: (params) => {
        if (typeof params.data !== 'undefined') {
          return params.data.name;
        }
        return "n/a";
      },
      valueSetter: function(params) {
          if (params.newValue.trim() !== '') {
            params.data.name = params.newValue.trim();
            self.onValueChanged('name', params.node.rowIndex, params.api, params.newValue.trim());
            return true;
          }
          return false;
      },
      editable: editable
      
    };
    
    if (this.mode === 'TAG') {
      this.autoGroupColumnDef.cellRendererParams.message = 'error.name_is_required';
      this.autoGroupColumnDef.cellRendererParams.errorMessage = 'nameErrorMessage';
      this.autoGroupColumnDef.valueSetter = async function(params) {
        if (params.newValue.trim() !== '') {
          const node = self.gridApi.getDisplayedRowAtIndex(params.node.rowIndex);
          params.data.name = params.newValue.trim();
          
          if (!params.data.nameErrorMessage ||
              params.data.nameErrorMessage === 'error.not_unique_key') {
            const data = [{ name: params.newValue.trim() }];
            const inUse = await getTagInUse(data);
            if (inUse.length !== 0) {
              self.$set(node.data, 'nameErrorMessage', 'error.not_unique_key');
            }
            else {
              self.$set(node.data, 'nameErrorMessage', null);
              self.$emit('dataChanged', { data: self.rowData }); // we need to ensure that the import button gets activated
            }
          }
               
          if (params.data.nameErrorMessage === 'error.not_unique_key') {
            const list = [];
            self.gridApi.forEachNode(node => list.push(node.data));
            // clear the error on the old value
            const oldinstances = list.filter(l => l.name.toLowerCase() === params.oldValue.toLowerCase());
            for (const old of oldinstances) {
              self.$set(old, 'nameErrorMessage', null);
            }
            
            // check for duplicates in the list
            const instances = list.filter(l => l.name && l.name === params.data.name);
            if (instances.length === 1) {
              self.$set(node.data, 'nameErrorMessage', null);
              self.$emit('dataChanged', { data: self.rowData }); // we need to ensure that the import button gets activated
            }
            else {
              for (const inst of instances) {
                inst.nameErrorMessage = 'error.not_unique_key';
              }
            }
          }
          return true;
        }
        return false;
      }
    }
    
    const filterOptions = [
      'empty',
      {
          displayKey: 'blanks',
          displayName: 'Blanks',
          test: function (filterValue, params) {
              const labelField = params.colDef.cellRendererParams ? params.colDef.cellRendererParams.labelField : null;
              const fieldName = params.column.colId !== 'ag-Grid-AutoColumn' ? params.column.colId : 'name';
              const cellValue = labelField && params.data[fieldName] ? (Array.isArray(params.data[fieldName]) && params.data[fieldName].length !== 0 ? params.data[fieldName][0][labelField] : params.data[fieldName][labelField]) : params.data[fieldName];
              return Array.isArray(cellValue) ? cellValue.length === 0 : !cellValue;
          },
          hideFilterInput: true,
      },
      {
          displayKey: 'errors',
          displayName: 'Errors',
          test: function (filterValue, params) {
              const error = params.colDef.cellRendererParams ? params.colDef.cellRendererParams.errorMessage : false;
              const isRequired = (params.colDef.cellRendererParams && params.colDef.cellRendererParams.required && !params.data[params.column.colId]) || 
                                 (params.colDef.cellRendererParams && params.colDef.cellRendererParams.required && params.colDef.cellRendererParams.requiredField && !params.data[params.column.colId][params.colDef.cellRendererParams.requiredField]) || 
                                 (self.requiredFields.includes(params.column.colId) && checkRequiredField(params.column.colId, params.data, self.mode, { currencies: self.optionCurrency, priorities: self.optionPriority }));
              return params.data[error] || isRequired;
          },
          hideFilterInput: true,
      },
      {
          displayKey: 'equals',
          displayName: 'Equals',
          test: function (filterValue, params) {
              const labelField = params.colDef.cellRendererParams ? params.colDef.cellRendererParams.labelField : null;
              const fieldName = params.column.colId !== 'ag-Grid-AutoColumn' ? params.column.colId : 'name';
              const cellValue = labelField && params.data[fieldName] ? (Array.isArray(params.data[fieldName]) && params.data[fieldName].length !== 0 ? params.data[fieldName][0][labelField] : params.data[fieldName][labelField]) : params.data[fieldName];
              
              if (params.colDef.cellRendererParams &&
                  params.colDef.cellRendererParams.options) {
                const entry = params.colDef.cellRendererParams.options.filter(f => f.value === cellValue);
                if (entry.length !== 0) {
                  return entry[0].text === filterValue;
                }
              }
              
              if (Array.isArray(cellValue)) {
                for (let i = 0; i < cellValue.length; i++) {
                  if (cellValue[i] === filterValue ||
                      cellValue[i].name === filterValue) {
                    return true;
                  }
                }
              }
              
              return cellValue === filterValue;
          },
      },
      {
          displayKey: 'notequals',
          displayName: 'Not Equals',
          test: function (filterValue, params) {
              const labelField = params.colDef.cellRendererParams ? params.colDef.cellRendererParams.labelField : null;
              const fieldName = params.column.colId !== 'ag-Grid-AutoColumn' ? params.column.colId : 'name';
              const cellValue = labelField && params.data[fieldName] ? (Array.isArray(params.data[fieldName]) && params.data[fieldName].length !== 0 ? params.data[fieldName][0][labelField] : params.data[fieldName][labelField]) : params.data[fieldName];
              
              if (params.colDef.cellRendererParams &&
                  params.colDef.cellRendererParams.options) {
                const entry = params.colDef.cellRendererParams.options.filter(f => f.value === cellValue);
                if (entry.length !== 0) {
                  return entry[0].text !== filterValue;
                }
              }
              
              if (Array.isArray(cellValue)) {
                for (let i = 0; i < cellValue.length; i++) {
                  if (cellValue[i] === filterValue ||
                      cellValue[i].name === filterValue) {
                    return true;
                  }
                }
              }
              
              return cellValue !== filterValue;
          },
      }
    ];
    
    this.defaultColDef = {
      sortable: false,
      resizable: true,
      editable: editable,
      minWidth: 100,
      cellEditor: 'stringEditor',
      cellEditorParams: { enterStopEditing: true },
      menuTabs: ['columnsMenuTab', 'filterMenuTab'], 
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: filterOptions,
        textFormatter: (cellValue) => {
          return cellValue;
        }
      },
      filterValueGetter: (params) => {
          const rowData = params;
          return rowData; // pass all row data
      }
    };
    
    if (this.preview) {
      if (this.projectId !== null || this.mode === 'TASKS') {
        this.populateTaskConstraint();
        this.populateStages();
        this.columnDefs = [
          {
            headerName: this.$t('task.field.taskType'),
            field: 'type',
            editable: false,
            minWidth: 120,
            hide: !(this.fields.includes('type')) && this.projectId === null
          },
          {
            headerName: this.$t('task.field.identifier'),
            field: 'identifier',
            minWidth: 120,
            hide: !(this.fields.includes('identifier'))
          },
          {
            headerName: this.$t('task.field.duration'),
            field: 'duration',
            cellEditor: 'durationEditor',
            cellStyle: {'border-width': '2px'},
            editable: (params) => { return params.data.type === 'Task' && !(self.editing)},
            hide: !(this.fields.includes('duration'))
          },
          {
            headerName: this.$t('task.field.startTime'),
            field: 'startdate',
            cellRenderer: 'dateTimeCellRenderer',
            cellEditor: 'dateTimeEditor',
            cellEditorParams: { placeholder: this.$t('task.field.startTime'), validation: (params, value) => {
                const result = value !== '' && typeof params.data.enddate !== 'undefined' && params.data.enddate !== '' ? value > params.data.enddate : false;
                if (result) {
                  self.$emit('error', self.$t('task.error.start_time_less_than_end_time'));
                }
                else if (params.data.type === 'Milestone') {
                  params.data.enddate = value;
                }
                return result;
              },
              optional: true
            },
            editable: (params) => { return params.data.type !== 'Summary Task' && !(self.editing)},
            hide: !(this.fields.includes('startdate'))
          },
          {
            headerName: this.$t('task.field.closeTime'),
            field: 'enddate',
            cellRenderer: 'dateTimeCellRenderer',
            cellEditor: 'dateTimeEditor',
            cellEditorParams: { placeholder: this.$t('task.field.closeTime'), validation: (params, value) => {
                const result = value !== '' && typeof params.data.startdate !== 'undefined' && params.data.startdate !== ''? value < params.data.startdate : false;
                if (result) {
                  self.$emit('error', self.$t('task.error.start_time_less_than_end_time'));
                }
                else if (params.data.type === 'Milestone') {
                  params.data.startdate = value;
                }
                return result;
              } ,
              optional: true
            },
            minWidth: 120,
            editable: (params) => { return params.data.type === 'Task' && !(self.editing)},
            hide: !(this.fields.includes('enddate'))
          },
          {
            headerName: this.$t('task.field.stage'),
            field: 'workflow_stage',
            cellEditor: 'listEditor',
            cellEditorParams: (params) => { 
              if (params.data.project && params.data.project.uuId) {
                return { options: params.data.project.stages }
              }
              else {
                return { options: this.optionStages }
              }
            },
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: (params) => { 
              if (params.data.project && params.data.project.uuId) {
                return { options: params.data.project.stages }
              }
              else {
                return { options: this.optionStages }
              }
            },
            cellStyle: {'border-width': '2px'},
            minWidth: 120,
            editable: (params) => { return params.data.type !== 'Summary Task' && !(self.editing)},
            hide: !(this.fields.includes('workflow_stage'))
          },
          {
            headerName: this.$t('task.field.complexity'),
            field: 'complexity',
            cellEditor: 'listEditor',
            cellEditorParams: { options: this.optionComplexity, isEnumType: true },
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: this.optionComplexity },
            cellStyle: {'border-width': '2px'},
            minWidth: 120,
            hide: !(this.fields.includes('complexity'))
          },
          {
            headerName: this.$t('task.field.rebates'),
            field: 'rebates',
            cellEditor: 'rebatesEditor',
            cellRenderer: 'rebateCellRenderer',
            cellStyle: {'border-width': '2px'},
            minWidth: 120,
            hide: !(this.fields.includes('rebates'))
          },
          {
            headerName: this.$t('task.field.progress'),
            field: 'progress',
            cellRenderer: 'percentageCellRenderer',
            cellEditor: 'percentageEditor',
            cellStyle: {'border-width': '2px'},
            minWidth: 120,
            editable: (params) => { return params.data.type === 'Task' && !(self.editing)},
            hide: !(this.fields.includes('progress'))
          },
          {
            headerName: this.$t('task.field.staffs'),
            field: 'staffs',
            minWidth: 130,
            cellRenderer: 'taskStaffCellRenderer',
            cellEditor: 'staffEditor',
            hide: !(this.fields.includes('staffs'))
          },
          {
            headerName: this.$t('task.field.resources'),
            field: 'resources',
            minWidth: 130,
            cellRenderer: 'taskResourceCellRenderer',
            cellEditor: 'resourceEditor',
            hide: !(this.fields.includes('resources'))
          },
          {
            headerName: this.$t('task.field.fixedCost'),
            field: 'fixedcost',
            cellEditor: 'costEditor',
            cellStyle: {'border-width': '2px'},
            cellRenderer: 'costCellRenderer',
            hide: !(this.fields.includes('fixedcost'))
          },
          {
            headerName: this.$t('task.field.fixedDuration'),
            field: 'fixedduration',
            cellEditor: 'durationEditor',
            cellStyle: {'border-width': '2px'},
            hide: !(this.fields.includes('fixedduration'))
          },
          {
            headerName: this.$t('task.field.skills'),
            field: 'skills',
            minWidth: 130,
            cellRenderer: 'taskSkillCellRenderer',
            cellEditor: 'skillEditor',
            hide: !(this.fields.includes('skills'))
          },
          {
            headerName: this.$t('task.field.description'),
            field: 'desc',
            minWidth: 130,
            cellEditor: 'multilineEditor',
            cellEditorParams: { title: this.$t('task.edit_description') },
            hide: !(this.fields.includes('desc')) && !(this.fields.includes('summary_desc'))
          },
          {
            headerName: this.$t('task.field.notes'),
            field: 'notes',
            cellEditor: 'multilineEditor',
            cellEditorParams: { title: this.$t('task.edit_note') },
            hide: !(this.fields.includes('notes'))
          },
          {
            headerName: this.$t('task.field.priority'),
            field: 'priority',
            cellEditor: 'listEditor',
            cellEditorParams: { options: this.optionPriority },
            cellRendererParams: { options: this.optionPriority },
            cellRenderer: 'priorityCellRenderer',
            hide: !(this.fields.includes('priority'))
          },
          {
            headerName: this.$t('task.field.constraint'),
            colId: 'constraintType',
            field: 'constraint',
            cellRenderer: 'constraintCellRenderer',
            cellEditor: 'constraintEditor',
            cellEditorParams: { field: 'type', options: this.optionConstraint },
            cellRendererParams: { field: 'type', options: this.optionConstraint },
            editable: (params) => { return params.data.type !== 'Summary Task' && !(self.editing) },
            hide: !(this.fields.includes('constraint'))
          },
          {
            headerName: this.$t('task.field.constraintTime'),
            colId: 'constraintTime',
            field: 'constraint',
            cellEditor: 'constraintEditor',
            cellEditorParams: { field: 'time', options: this.optionConstraint },
            cellRenderer: 'constraintCellRenderer',
            cellRendererParams: { field: 'time' },
            minWidth: 130,
            editable: (params) => { return params.data.type !== 'Summary Task' && !(self.editing) },
            hide: !(this.fields.includes('constraint'))
          },
          {
            headerName: this.$t('task.field.autoScheduling'),
            field: 'schedulemode',
            cellEditor: 'listEditor',
            cellRenderer: 'taskAutoSchedulingCellRenderer',
            cellEditorParams: { 
              options: [
                { value: true, text: this.$t('task.autoschedule.auto') },
                { value: false, text: this.$t('task.autoschedule.manual') }
              ]
            },
            minWidth: 130,
            editable: (params) => { return params.data.type !== 'Summary Task' && !(self.editing)},
            hide: !(this.fields.includes('schedulemode'))
          },
          {
            headerName: this.$t('task.field.currencyCode'),
            field: 'currency',
            cellEditor: 'listEditor',
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: this.optionCurrency },
            cellEditorParams: { options: this.optionCurrency, isEnumType: true },
            hide: !(this.fields.includes('currency'))
          },
          {
            headerName: this.$t('task.field.count'),
            field: 'count',
            cellEditor: 'numericEditor',
            cellStyle: {'border-width': '2px'},
            hide: !(this.fields.includes('count'))
          },
          {
            headerName: this.$t('task.field.template'),
            field: 'templateUuid',
            cellRenderer: 'taskTemplateRenderer',
            cellRendererParams: {
              showFunc: () => true
            },
            cellEditor: 'taskTemplateEditor',
            cellEditorParams: { 
                                setLabel: true, 
                                onChange: ({uuIds, names, params}) => { 
                                  params.data.templateUuid = { uuIds: uuIds, names: names };
                                  self.$emit('dataChanged', { data: self.rowData });
                                } 
                              },
            minWidth: 120,
            editable: (params) => { return params.data.type === 'Summary Task' && !(self.editing) },
            cellStyle: (params) => { return params.data && params.data.type !== 'Summary Task' ? { 'background-color': 'var(--ag-template-cell-disabled)' } : {} },
            hide: self.projectId === null
          },
          {
            headerName: this.$t('task.field.image'),
            field: 'image',
            minWidth: 150,
            hide: !(this.fields.includes('image')),
            cellRenderer: 'imageCellRenderer',
            cellRendererParams: {
              enableReadonlyStyle: true,
              isPreview: true
            },
            cellEditor: 'imageEditor',
            editable: () => { return !(self.editing) }
          },
          {
            headerName: this.$t('task.field.project'),
            field: 'project',
            minWidth: 130,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name', required: !self.projectId, requiredField: 'uuId', errorMessage: 'task.error.project' },
            cellEditor: 'projectEditor',
            hide: !(this.fields.includes('project'))
          }
        ];
        
        // make sure the custom fields are selectable for import
        if (this.customFields) {
          for (const cfield of this.customFields) {
            const found = this.columnDefs.find(v => v.field === cfield.name);
            if (!found) {
              if (this.requiredFields.includes(cfield.name)) {
                this.columnDefs.push(
                {
                  headerName: cfield.displayName,
                  field: cfield.name,
                  minWidth: 120,
                  cellRenderer: 'errorIconCellRenderer',
                  cellRendererParams: { 
                    message: 'required',
                    hasError: (params) => {
                      if (!params.value) {
                        return !params.value;
                      }
                      const result = customFieldValidateAndType(params.value, params.column.colId, this.customFields);
                      if (result) {
                        const cfield = this.customFields.find(c => c.name === params.column.colId);
                        if (cfield && cfield.description) {
                          return cfield.description;
                        }
                        return 'type_error';
                      }
                      return false;
                    }
                  },
                  hide: !(this.fields.includes(cfield.name))
                });
              }
              else {
                this.columnDefs.push(
                {
                  headerName: cfield.displayName,
                  field: cfield.name,
                  minWidth: 120,
                  cellRenderer: 'errorIconCellRenderer',
                  cellRendererParams: { 
                    message: 'type_error',
                    hasError: (params) => {
                      const result = customFieldValidateAndType(params.value, params.column.colId, this.customFields);
                      if (result) {
                        const cfield = this.customFields.find(c => c.name === params.column.colId);
                        if (cfield && cfield.description) {
                          return cfield.description;
                        }
                        return 'type_error';
                      }
                      return false;
                    }
                  },
                  hide: !(this.fields.includes(cfield.name))
                });
              }
            }
          }
        }
      }
      else {
        this.prepareColumnDefs();
      }
    }

    this.context = {
      componentParent: self
    };
    
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });
    document.addEventListener('click', this.stopEditing);
    const viewport = document.querySelector(".ag-center-cols-viewport");
    if (viewport) {
      viewport.addEventListener('click', this.onClickGrid);
    }    
  },
  created() {
    this.getDurationConversionOpts();
    this.delimiter = '__';
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
    this.delimiter = null;
    document.removeEventListener('click', this.stopEditing);
    const viewport = document.querySelector(".ag-center-cols-viewport");
    if (viewport) {
      viewport.removeEventListener('click', this.onClickGrid);
    }
  },
  computed: {
    overlayNoRowsTemplate() {
      const mode = this.mode;  
      if (mode === "TASKS") {
        return `<span class='grid-overlay'>${ this.$t('task.grid.no_data') }</span>`;
      }
      else if (mode === "BOOKING") {
        return `<span class='grid-overlay'>${ this.$t('booking.grid.no_data') }</span>`;
      }
      else if (mode === "ACTIVITY") {
        return `<span class='grid-overlay'>${ this.$t('activity.grid.no_data') }</span>`;
      }
      else if (mode === "NONWORK") {
        return `<span class='grid-overlay'>${ this.$t('vacation.grid.no_data') }</span>`;
      }
      else if (mode === "COMPANY") {
        return `<span class='grid-overlay'>${ this.$t('company.grid.no_data') }</span>`;
      }
      else if (mode === "DEPARTMENT") {
        return `<span class='grid-overlay'>${ this.$t('department.grid.no_data') }</span>`;
      }
      else if (mode === "STAFF" || mode === "STAFF_GENERIC") {
        return `<span class='grid-overlay'>${ this.$t('staff.grid.no_data') }</span>`;
      }
      else if (mode === "CUSTOMER") {
        return `<span class='grid-overlay'>${ this.$t('customer.grid.no_data') }</span>`;
      }
      else if (mode === "PROJECT") {
        return `<span class='grid-overlay'>${ this.$t('project.grid.no_data') }</span>`;
      }
      else if (mode === "STAGE") {
        return `<span class='grid-overlay'>${ this.$t('stage.grid.no_data') }</span>`;
      }
      else if (mode === "TASK_TEMPLATE") {
        return `<span class='grid-overlay'>${ this.$t('template.grid.no_data') }</span>`;
      }
      else if (mode === "SKILL") {
        return `<span class='grid-overlay'>${ this.$t('skill.grid.no_data') }</span>`;
      }
      else if (mode === "RESOURCE") {
        return `<span class='grid-overlay'>${ this.$t('resource.grid.no_data') }</span>`;
      }
      else if (mode === "LOCATION") {
        return `<span class='grid-overlay'>${ this.$t('location.grid.no_data') }</span>`;
      }
      else if (mode === "REBATE") {
        return `<span class='grid-overlay'>${ this.$t('rebate.grid.no_data') }</span>`;
      }
      return `<span class='grid-overlay'>${ this.$t('task.grid.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    totalTasks() {
      let totalCount = 0;
      if (this.rowData) {
        for (const item of this.rowData) {
          // update the count
          if (item.count) {
            totalCount += parseInt(item.count);
          }
          else {
            totalCount++;
          }
        }
      }
      return totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    deleteMessage() {
      return this.$t(`${this.selected.length > 1? 'confirmation.delete_plural':'confirmation.delete'}`);
    }
  },
  methods: {
    async prepareColumnDefs() {
      const self = this;
      this.columnDefs = [];
      for (const prop of this.properties) {
        const field = prop.value;
        if (field === 'skills') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.skills'),
            field: field,
            minWidth: 120,
            cellRenderer: 'taskSkillCellRenderer',
            cellEditor: 'skillEditor',
            hide: !(this.fields.includes('skills'))
          });
        }
        else if (field === 'rebate') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.rebates'),
            field: 'rebate',
            cellEditor: 'numericEditor', //'rebateEditor',
            // cellRenderer: 'rebateCellRenderer',
            cellStyle: {'border-width': '2px'},
            minWidth: 120
          });
        }
        else if (field === 'rebates') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.rebates'),
            field: 'rebates',
            cellEditor: 'rebatesEditor',
            cellRenderer: 'rebateCellRenderer',
            cellStyle: {'border-width': '2px'},
            minWidth: 120
          });
        }
        else if (field === 'progress') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.progress'),
            field: 'progress',
            cellRenderer: 'percentageCellRenderer',
            cellEditor: 'percentageEditor',
            cellStyle: {'border-width': '2px'},
            minWidth: 120,
            hide: !(this.fields.includes('progress'))
          });
        }
        else if (field === 'starttime') {
          this.columnDefs.push(
          {
              headerName: this.$t('task.field.startTime'),
              field: 'starttime',
              cellRenderer: 'dateTimeCellRenderer',
              cellEditor: 'dateTimeEditor',
              cellEditorParams: { placeholder: this.$t('task.field.startTime'), validation: (params, value) => {
                  const result = value !== '' && typeof params.data.closetime !== 'undefined' && params.data.closetime !== '' ? value > params.data.closetime : false;
                  if (result) {
                    self.$emit('error', self.$t('task.error.start_time_less_than_end_time'));
                  }
                  return result;
                },
                optional: true
              },
              hide: !(this.fields.includes('starttime'))
            });
          }
          else if (field === 'closetime') {
            this.columnDefs.push(
            {
              headerName: this.$t('task.field.closeTime'),
              field: 'closetime',
              cellRenderer: 'dateTimeCellRenderer',
              cellEditor: 'dateTimeEditor',
              cellEditorParams: { placeholder: this.$t('task.field.closeTime'), validation: (params, value) => {
                  const result = value !== '' && typeof params.data.starttime !== 'undefined' && params.data.starttime !== ''? value < params.data.starttime : false;
                  if (result) {
                    self.$emit('error', self.$t('task.error.start_time_less_than_end_time'));
                  }
                  return result;
                },
                optional: true
              },
              hide: !(this.fields.includes('closetime'))
            });
        }
        else if (field === 'startdate') {
          this.columnDefs.push({
            headerName: self.$t('staff.field.startDate'),
            field: 'startdate',
            cellRenderer: 'dateTimeCellRenderer',
            cellRendererParams: { placeholder: this.$t('staff.field.startDate'), validation: (params, value) => {
                const result = value !== '' && typeof params.data.enddate !== 'undefined' && params.data.enddate !== ''? moment(params.data.startdate) > moment(params.data.enddate) : false;
                if (result) {
                  return self.$t('task.error.start_time_less_than_end_time');
                }
                return null;
              },
              optional: true
            },
            cellEditor: 'dateTimeEditor',
            cellEditorParams: { placeholder: this.$t('staff.field.startDate'), validation: (params, value) => {
                const result = value !== '' && typeof params.data.enddate !== 'undefined' && params.data.enddate !== ''? value > params.data.enddate : false;
                if (result) {
                  self.$emit('error', self.$t('task.error.start_time_less_than_end_time'));
                }
                return result;
              },
              optional: true
            },
            minWidth: 100,
            hide: !(this.fields.includes('startdate'))
          });
        }
        else if (field === 'enddate') {
          this.columnDefs.push({
          headerName: self.$t('staff.field.endDate'),
          field: 'enddate',
          cellRenderer: 'dateTimeCellRenderer',
          cellRendererParams: { placeholder: this.$t('staff.field.endDate'), validation: (params, value) => {
              const result = value !== '' && typeof params.data.startdate !== 'undefined' && params.data.startdate !== ''? moment(params.data.startdate) < moment(params.data.startdate) : false;
              if (result) {
                return self.$t('task.error.start_time_less_than_end_time');
              }
              return null;
            },
            optional: true
          },
          cellEditor: 'dateTimeEditor',
          cellEditorParams: { placeholder: this.$t('staff.field.endDate'), validation: (params, value) => {
              const result = value !== '' && typeof params.data.startdate !== 'undefined' && params.data.startdate !== ''? value < params.data.startdate : false;
              if (result) {
                self.$emit('error', self.$t('task.error.start_time_less_than_end_time'));
              }
              return result;
            },
            optional: true
          },
          minWidth: 100,
          hide: !(this.fields.includes('enddate'))
        });
        }
        else if (field === 'resources') {
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.resources'),
            field: field,
            minWidth: 120,
            cellRenderer: 'taskResourceCellRenderer',
            cellRendererParams: { 
              message: 'required',
              hasError: (params) => {
                if (!params.value && this.requiredFields.includes(field)) {
                  return !params.value;
                }
                return false;
              }
            },
            cellEditor: 'resourceEditor',
            hide: !(this.fields.includes('resources'))
          });
        }
        else if (field === 'customer') {
          this.columnDefs.push(
          {
            headerName: this.$t('project.field.customer'),
            field: field,
            minWidth: 120,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name' },
            cellEditor: 'customerEditor',
            hide: !(this.fields.includes('customer'))
          });
        }
        else if (field === 'company') {
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.company'),
            field: field,
            minWidth: 120,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name', required: self.mode !== "PROJECT", requiredField: 'uuId', errorMessage: 'staff.error.company' },
            cellEditor: 'companyEditor',
            hide: !(this.fields.includes('company'))
          });
        }
        else if (field === 'stages') {
          this.columnDefs.push(
          {
            headerName: this.$t('project.field.stages'),
            field: field,
            minWidth: 120,
            cellRenderer: 'arrayCellRenderer',
            cellRendererParams: { labelField: 'name' },
            cellEditor: 'stagesEditor'
          });
        }
        else if (field === 'priority') {
          this.columnDefs.push(
          {
            headerName: this.$t('project.field.priority'),
            field: 'priority',
            cellEditor: 'listEditor',
            cellEditorParams: { options: this.optionPriority },
            cellRendererParams: { options: this.optionPriority },
            cellRenderer: 'priorityCellRenderer'
          });
        }
        else if (field === 'status') {
          this.columnDefs.push(
          {
            headerName: this.$t('project.field.status'),
            field: field,
            minWidth: 120,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name' },
            cellEditor: 'stageEditor'
          });
        }
        else if (field === 'department') {
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.department'),
            field: field,
            minWidth: 120,
            cellRenderer: 'arrayCellRenderer',
            cellRendererParams: { labelField: 'name' },
            cellEditor: 'departmentEditor',
            hide: !(this.fields.includes('department'))
          });
        }
        else if (field === 'location') {
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.location'),
            field: field,
            minWidth: 120,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name', required: self.mode !== "PROJECT" && self.mode !== "COMPANY", requiredField: 'uuId', errorMessage: 'staff.error.location' },
            cellEditor: 'locationEditor',
            hide: !(this.fields.includes('location'))
          });
        }
        else if (this.skillLevelNames.includes(field)) {
          this.columnDefs.push(
          {
            headerName: field,
            field: field,
            minWidth: 140,
            cellRenderer: 'errorIconCellRenderer',
            cellEditor: 'numericEditor',
            cellRendererParams: { 
              message: 'skill.error.level_number',
              errorMessage: 'skillErrorMessage',
              hasError: (params) => {
                let result = typeof params.value === 'undefined' || params.value < 1;
                if (result) {
                  params.data['skillErrorMessage'] = this.$t('skill.error.must_greater_than', [field, 0]);
                } else if (params.value > 365) {
                  result = true
                  params.data['skillErrorMessage'] = this.$t('skill.error.must_lesser_than', [field, 366]);
                }
                return result;
              }
            }
          });
        }
        else if (field === 'email') {
          this.columnDefs.unshift(
          {
            //headerName: this.$t('task.field.taskType'),
            field: field,
            minWidth: 140,
            cellRenderer: 'errorIconCellRenderer',
            cellRendererParams: { 
              message: 'staff.error.email_is_required',
              errorMessage: 'emailErrorMessage'
            },
            valueSetter: async function(params) {
                if (params.newValue.trim() !== '') {
                  const node = self.gridApi.getDisplayedRowAtIndex(params.node.rowIndex);
                  params.data.email = params.newValue.trim();
                  const valid = validateEmail(params.data.email);
                  if (!valid) {
                    self.$set(node.data, 'emailErrorMessage', 'staff.error.email');
                  }
                  else if (params.data.emailErrorMessage === 'staff.error.email') {
                    self.$set(node.data, 'emailErrorMessage', null);
                  }
                  
                  if (!params.data.emailErrorMessage ||
                      params.data.emailErrorMessage === 'staff.error.email_is_used') {
                    const data = [{ email: params.newValue.trim() }];
                    const inUse = await getStaffEmailInUse(data);
                    if (inUse.length !== 0) {
                      self.$set(node.data, 'emailErrorMessage', 'staff.error.email_is_used');
                    }
                    else {
                      self.$set(node.data, 'emailErrorMessage', null);
                      self.$emit('dataChanged', { data: self.rowData }); // we need to ensure that the import button gets activated
                    }
                  }
                       
                  if (params.data.emailErrorMessage === 'staff.error.email_duplicate') {
                    const list = [];
                    self.gridApi.forEachNode(node => list.push(node.data));
                    // clear the error on the old value
                    const oldinstances = list.filter(l => l.email === params.oldValue);
                    for (const old of oldinstances) {
                      self.$set(old, 'emailErrorMessage', null);
                    }
                    
                    // check for duplicates in the list
                    const instances = list.filter(l => l.email && l.email === params.data.email);
                    if (instances.length === 1) {
                      self.$set(node.data, 'emailErrorMessage', null);
                      self.$emit('dataChanged', { data: self.rowData }); // we need to ensure that the import button gets activated
                    }
                    else {
                      for (const inst of instances) {
                        inst.emailErrorMessage = 'staff.error.email_duplicate';
                      }
                    }
                  }
                  return true;
                }
                return false;
            }
          });
        }
        else if (field === 'paycurrency' ||
                 field === 'currency') {
          this.columnDefs.push(
          {
            headerName: this.mode === 'RESOURCE' ? this.$t('resource.field.currency') : this.$t('staff.field.payCurrency'),
            field: field,
            cellEditor: 'listEditor',
            cellEditorParams: { options: this.optionCurrency, isEnumType: true },
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: this.optionCurrency, required: true, errorMessage: 'project.error.currency_code' },
            minWidth: 120
          });
        }
        else if (field === 'fixedcost') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.fixedCost'),
            field: 'fixedcost',
            cellEditor: 'costEditor',
            cellStyle: {'border-width': '2px'},
            cellRenderer: 'costCellRenderer'
          });
        }
        else if (field === 'identifier') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.identifier'),
            field: 'identifier',
            minWidth: 120,
          });
        }
        else if (field === 'payfrequency' ||
                 field === 'frequency') {
          const payFreqs = payFrequencies(this).filter(p => 
          ((self.mode === 'STAFF' || self.mode === 'STAFF_GENERIC') && p.value !== 'One_shot') || (self.mode !== 'STAFF' && self.mode !== 'STAFF_GENERIC'));
          
          this.columnDefs.push(
          {
            headerName: this.mode === 'RESOURCE' ? this.$t('resource.field.payFrequency') : this.$t('staff.field.payFrequency'),
            field: field,
            cellEditor: 'listEditor',
            cellEditorParams: { options: payFreqs },
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: payFreqs, required: true, errorMessage: 'staff.error.pay_frequency' },
            minWidth: 120
          });
        }
        else if (field === 'type' && 
                 (this.mode === 'STAFF' || this.mode === 'STAFF_GENERIC')) {
          await this.getModelInfo();
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.type'),
            field: field,
            cellEditor: 'listEditor',
            cellEditorParams: { options: this.filteredStaffTypeOptions() },
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: this.filteredStaffTypeOptions(), required: true, errorMessage: 'staff.error.type' },
            minWidth: 120,
            hide: !(this.fields.includes(field))
          });
        }
        else if (field === 'type' && 
                 this.mode === 'COMPANY') {
          await this.getModelInfo();
          this.columnDefs.push(
          {
            headerName: this.$t('company.field.type'),
            field: field,
            cellEditor: 'listEditor',
            cellEditorParams: { options: this.filteredCompanyTypeOptions().filter(c => c.value !== 'Primary') },
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: this.filteredCompanyTypeOptions(), required: true, errorMessage: 'company.error.type' },
            minWidth: 120,
            hide: !(this.fields.includes(field))
          });
        }
        else if (field === 'payamount') {
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.payAmount'),
            field: field,
            minWidth: 120,
            cellRenderer: 'errorIconCellRenderer',
            cellRendererParams: { 
              message: 'staff.error.pay_amount',
              errorMessage: 'payamountErrorMessage'
            }
          });
        }
        else if (field === 'cost') {
          this.columnDefs.push(
          {
            headerName: this.$t('resource.field.payAmount'),
            field: field,
            minWidth: 120,
            cellRenderer: 'errorIconCellRenderer',
            cellRendererParams: { 
              message: 'staff.error.pay_amount',
              errorMessage: 'costErrorMessage'
            }
          });
        }
        else if (field === 'country') {
          this.columnDefs.push(
          {
            headerName: this.$t('location.field.country'),
            field: field,
            minWidth: 120,
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: countryCodes, required: true, errorMessage: 'location.error.country' },
            cellEditor: 'listEditor',
            cellEditorParams: { options: countryCodes }
          });
        }
        else if (field === 'currencycode') {
          this.columnDefs.push(
          {
            headerName: this.$t('project.field.currencyCode'),
            field: field,
            minWidth: 120,
            cellEditor: 'listEditor',
            cellRenderer: 'enumCellRenderer',
            cellRendererParams: { options: this.optionCurrency, required: true, errorMessage: 'project.error.currency_code'  },
            cellEditorParams: { options: this.optionCurrency, isEnumType: true }
          });
        }
        else if (field === 'schedulemode') {
          this.columnDefs.push(
          {
            headerName: this.$t('project.field.autoScheduling'),
            field: 'schedulemode',
            cellEditor: 'listEditor',
            cellRenderer: 'taskAutoSchedulingCellRenderer',
            cellEditorParams: { 
              options: [
                { value: true, text: this.$t('task.autoschedule.auto') },
                { value: false, text: this.$t('task.autoschedule.manual') }
              ]
            },
            minWidth: 130
          });
        }
        else if (field === 'desc') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.description'),
            field: 'desc',
            minWidth: 130,
            cellEditor: 'multilineEditor',
            cellEditorParams: { title: this.$t('task.edit_description') }
          });
        }
        else if (field === 'firstname') {
          this.columnDefs.push(
          {
            headerName: this.$t('staff.field.firstName'),
            field: 'firstname',
            minWidth: 130,
          });
        }
        else if (field === 'notes') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.notes'),
            field: 'notes',
            cellEditor: 'multilineEditor',
            cellEditorParams: { title: this.$t('task.edit_note') }
          });
        }
        else if (field === 'staffs' && this.mode !== 'NONWORK') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.staffs'),
            field: 'staffs',
            minWidth: 130,
            cellRenderer: 'taskStaffCellRenderer',
            cellRendererParams: { labelField: 'name', required: this.mode === 'BOOKING' || this.mode === 'ACTIVITY', requiredField: 'uuId', errorMessage: 'booking.error.staff' },
            cellEditor: 'staffEditor',
            hide: !(this.fields.includes('staffs'))
          });
        }
        else if (field === 'staffs' && this.mode === 'NONWORK') {
          this.columnDefs.push(
          {
            headerName: this.$t('entity_selector.staff_selector'),
            field: 'staffs',
            minWidth: 130,
            cellRenderer: 'taskStaffCellRenderer',
            cellEditor: 'staffEditor',
            cellEditorParams: {
              hideUtilization: true
            },
            hide: !(this.fields.includes('staffs'))
          });
        }
        else if (field === 'project') {
          this.columnDefs.push(
          {
            headerName: this.$t('booking.field.project'),
            field: 'project',
            minWidth: 130,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name', required: true, requiredField: 'uuId', errorMessage: 'booking.error.project' },
            cellEditor: 'projectEditor',
            hide: !(this.fields.includes('project'))
          });
        }
        else if (field === 'stages') {
          this.columnDefs.push(
          {
            headerName: this.$t('booking.field.stage'),
            field: 'stages',
            minWidth: 130,
            cellRenderer: 'objectCellRenderer',
            cellRendererParams: { labelField: 'name' },
            cellEditor: 'stageEditor',
            hide: !(this.fields.includes('stages'))
          });
        }
        else if (field === 'fixedCost') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.fixedCost'),
            field: 'fixedcost',
            cellEditor: 'costEditor',
            cellStyle: {'border-width': '2px'},
            cellRenderer: 'costCellRenderer',
            hide: !(this.fields.includes('fixedcost'))
          });
        }
        else if (field === 'fixedDuration') {
          this.columnDefs.push(
          {
            headerName: this.$t('task.field.fixedDuration'),
            field: 'fixedduration',
            cellEditor: 'durationEditor',
            cellStyle: {'border-width': '2px'},
            hide: !(this.fields.includes('fixedduration'))
          });
        }
        else if (field !== 'name' &&
                 field !== 'task_path') {
          if (this.requiredFields.includes(field)) {
            this.columnDefs.push(
            {
              field: field,
              minWidth: 120,
              cellRenderer: 'errorIconCellRenderer',
              cellRendererParams: { 
                message: 'required',
                hasError: (params) => {
                  if (!params.value) {
                    return !params.value;
                  }
                  const result = customFieldValidateAndType(params.value, params.column.colId, this.customFields);
                  if (result) {
                    const cfield = this.customFields.find(c => c.name === params.column.colId);
                    if (cfield && cfield.description) {
                      return cfield.description;
                    }
                    return 'type_error';
                  }
                  return false;
                }
              },
              hide: !(this.fields.includes(field))
            });
          }
          else {
            this.columnDefs.push(
            {
              field: field,
              minWidth: 120,
              cellRenderer: 'errorIconCellRenderer',
              cellRendererParams: { 
                message: 'type_error',
                hasError: (params) => {
                  const result = customFieldValidateAndType(params.value, params.column.colId, this.customFields);
                  if (result) {
                    const cfield = this.customFields.find(c => c.name === params.column.colId);
                    if (cfield && cfield.description) {
                      return cfield.description;
                    }
                    return 'type_error';
                  }
                  return false;
                }
              },
              hide: !(this.fields.includes(field))
            });
          }
        }
      }
    },
    async getModelInfo() {
      const self = this;
      const mode = this.mode != 'STAFF_GENERIC' ? this.mode : 'STAFF';
      await this.$store.dispatch('data/info', {type: "api", object: mode}).then(value => {
        self.modelInfo = value[mode].properties;
        if (this.mode === 'COMPANY') {
          self.typeOptions = self.modelInfo.filter(f => f.field === 'type')[0].options.map(i => {
            return { value: i, text: i }
          });
        }
        else {
          const rawTypeOptions = self.modelInfo.filter(f => f.field === 'staffType')[0].options;
          self.typeOptions = rawTypeOptions.map(i => {
            return { value: i, text: i }
          });
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    filteredStaffTypeOptions() {
      if (typeof this.typeOptions !== 'function') {
        return this.typeOptions;
      }
      return [];
    },
    filteredCompanyTypeOptions() {
      if (typeof this.typeOptions !== 'function') {
        return this.typeOptions;
      }
      return [];
    },
    onClickGrid(event) {
      const src = event.target;
      const viewport = document.querySelector(".ag-center-cols-viewport");
      if (viewport) {
        if (viewport === src) {
          this.gridApi.stopEditing();
        }
      }
    },
    stopEditing(event) {
      const src = event.target;
      var parentElement = src.parentElement;
      var isGrid = src.classList.contains('ag-cell-wrapper');
      var isEmptyArea = src.classList.contains('ag-center-cols-viewport');
      var element = document.getElementById('preview-grid');
      var rect = element.getBoundingClientRect();
      
      if (isEmptyArea &&
          this.gridApi !== null) {
        // user clicked on the empty area in the grid where no rows exist
        this.gridApi.stopEditing();
        return;
      }
      
      // Some Span elements do not get detected by element.contains so
      // the client coordinates must be used
      if ((src !== element && element.contains(src)) ||
          (event.clientX > rect.left && event.clientX < rect.right &&
           event.clientY > rect.top && event.clientY < rect.bottom)) {
        isGrid = true;
      }
  
      var isModal = src.firstChild &&
                    typeof src.firstChild.classList !== 'undefined' && 
                    src.firstChild.classList.contains('modal-dialog');
      while (parentElement) {
        if (parentElement.id === 'preview-grid') {
          isGrid = true;
          break;
        }
        else if (parentElement.classList.contains('modal-content')) {
          if (!(parentElement.id.startsWith('preview-modal'))) {
            isModal = true;
          }
          break;
        }
        parentElement = parentElement.parentElement;
      }
      
      if (!isGrid && !isModal &&
          this.gridApi !== null) {
        this.gridApi.stopEditing();
      }
    },
    async onValueChanged(colId, rowIndex, api, newValue, oldValue) {
      const nodes = api.getSelectedNodes();
      // check if rowIndex is one of the childIndex selected
      const selected = nodes.filter(n => n.rowIndex === rowIndex);
      
      if (selected.length !== 0 && colId !== 'email') {
        if (this.mode === 'TASKS' && (colId == 'startdate'|| colId == 'enddate' || colId == 'duration' || colId == 'constraintType' || colId == 'constraintTime' || colId == 'autoScheduling')) {
          if (colId == 'startdate'|| colId == 'enddate') {
            const foundIdx = nodes.findIndex(n => n.rowIndex === rowIndex);
            if (foundIdx > -1) {
              nodes[foundIdx].data[colId] = oldValue;
            }
          } else if (colId == 'constraintType' || colId == 'constraintTime') {
            const foundIdx = nodes.findIndex(n => n.rowIndex === rowIndex);
            if (foundIdx > -1) {
              nodes[foundIdx].data.constraint = oldValue;
            }
          }
          this.dateTimeDurationValueChanged(nodes, colId, newValue);
          return;
        }
        else {
          for (const node of nodes) {
            if (colId === 'constraintType' ||
                colId === 'constraintTime') {
              if (node.data.type !== 'Summary Task') {
                node.data['constraint'] = newValue;
              }   
            }
            else if (colId === 'templateUuid') {
              if (node.data.type === 'Summary Task') {
                node.data[colId] = newValue;
              }
            }
            else if ((colId !== 'duration') ||
                  (colId === 'duration' && node.data.type === 'Task')) {
              node.data[colId] = newValue;
              
              if (this.mode === 'STAFF' &&
                  colId === 'company' &&
                  oldValue !== newValue) {
                node.data['department'] = null;   
              }
            }
          }
        }
        api.refreshCells({ rowNodes: nodes });
      } else {
        const node = api.getDisplayedRowAtIndex(rowIndex);
        
        if (this.mode === 'TASKS' && (colId == 'startdate'|| colId == 'enddate' || colId == 'duration' || colId == 'constraintType' || colId == 'constraintTime' || colId == 'autoScheduling')) {
          if (colId == 'startdate'|| colId == 'enddate') {
            node.data[colId] = oldValue;
          } else if (colId == 'constraintType' || colId == 'constraintTime') {
            node.data.constraint = oldValue;
          }
          this.dateTimeDurationValueChanged([node], colId, newValue);
          return;
        }
        else if (colId === 'constraintType' ||
            colId === 'constraintTime') {
          if (node.data.type !== 'Summary Task') {
            node.data['constraint'] = newValue;
          }   
        }
        else if (colId === 'templateUuid') {
          if (node.data.type === 'Summary Task') {
            node.data[colId] = newValue;
          }
        }
        api.redrawRows({ rowNodes: nodes.length !== 0 ? nodes : [node] });
      }
      
      this.$emit('dataChanged', { data: this.rowData, colId: colId });
    },
    detailLinkLabel(params) {
      if (params.column.colId === 'templateUuid') {
        return params.data.templateName;
      }
      return params.node.data.label;
    },
    async populateTaskConstraint() {
      if (this.optionConstraint.length !== 0) {
        return;
      }
      
      const service = taskService;
      let list = await service.optionConstraint()
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      this.constraintMap = {};
      const self = this;
      this.optionConstraint.splice(0, this.optionConstraint.length, ...list.map(i => { 
        self.constraintMap[this.$t(`constraint_type.${i.label}`)] = i.label;
        return { value: i.label, text: this.$t(`constraint_type.${i.label}`)} 
      }));
    },
    httpAjaxError(e) {
      console.error(e); // eslint-disable-line
    },
    async populateStages() {
      if (this.optionStages.length !== 0 ||
          this.projectId === null) {
        return;
      }
      
      const service = projectService;
      let result = await service.get([{ uuId: this.projectId }], ['STAGE_LIST'])
      .then(response => {
        const data = response.data ? response.data[response.data.jobCase] : [];
        if (data.length > 0) {
          return { list: data[0].stageList, currencyCode: data[0].currencyCode, scheduleMode: data[0].scheduleMode };
        }
        return { list: [], currencyCode: null, scheduleMode: null };
      })
      .catch(e => {
        this.httpAjaxError(e);
        return { list: [], currencyCode: null, scheduleMode: null };
      });
      if (result.currencyCode != null && this.optionCurrency.find(i => i.value == result.currencyCode) != null) {
        this.currencyCode = result.currencyCode;  
      }
      this.scheduleMode = result.scheduleMode;
      if (result.list) {
        this.$emit('stages', result.list);
        this.optionStages.splice(0, this.optionStages.length, ...result.list.map(i => { 
          return { value: i.name, text: i.name } 
        }));
      }
    },
    label(params) {
      return params.data? this.$t(`permission.${params.data.label.toLowerCase()}`): this.$t(`permission.${params.value.toLowerCase()}`);
    },
    async prepareTasks() {
      this.taskMap = {};
      if (this.preview) {
        await this.populateTaskConstraint();
        await this.populateStages();
      }
      
      for (const task of this.taskData) {
        if (typeof task.schedulemode === 'undefined' ||
            typeof task.schedulemode === 'string') {
          task.schedulemode = task.schedulemode !== "false" ? true : false;
        }
        if (task.type === 'Milestone') {
          task.duration = '0D';
        }
        
        if (task.fixedcost) {
          // strip all non-digit characters
          task.fixedcost = task.fixedcost.replace(/[^0-9.]/g, '');
        }
        
        if (this.mode === 'TASKS') {
          if (!task.currency == null || this.optionCurrency.find(i => i.value == task.currency) == null) {
            task.currency = this.currencyCode;
          }
        }

        if (task.payfrequency && task.payFrequency !== 'One_shot') {
          // make sure it is camel case like Daily, Monthly, Weekly
          let str = task.payfrequency.toLowerCase();
          task.payfrequency = str.charAt(0).toUpperCase() + str.slice(1);
          if (task.payfrequency !== 'Daily' && task.payfrequency.toLowerCase().includes('daily')) {
            task.payfrequency = 'Daily';
          }
          else if (task.payfrequency !== 'Weekly' && task.payfrequency.toLowerCase().includes('weekly')) {
            task.payfrequency = 'Weekly';
          }
          else if (task.payfrequency !== 'Monthly' && task.payfrequency.toLowerCase().includes('monthly')) {
            task.payfrequency = 'Monthly';
          }
        }
        
        if (task.duration && typeof task.duration === 'string') {
          task.duration = task.duration.trim();
        }
        
        if (task.duration === '0') {
          task.duration = null;
        }
        // eslint-disable-next-line
        else if (task.duration && /^[\d,\.]+$/.test(task.duration)) {
          task.duration = `${task.duration}D`;
        }
        // eslint-disable-next-line
        else if (task.duration && /^[\d,\.,D,m,h,W,M,Y]+$/.test(task.duration)) {
          if (task.duration.startsWith('0')) {
            task.duration === null;
          }
          else {
            task.duration = `${task.duration}`;
          }
        }
        // eslint-disable-next-line
        else if (task.duration && /^[\d,\.,w]+$/.test(task.duration)) {
          task.duration = `${task.duration.substr(0, task.duration.length -1)}W`;
        }
        else {
          task.duration = null;
        }
        
        if (task.count && !(/^\d+$/.test(task.count))) {
          task.count = null;
        }
        else if (task.count && parseInt(task.count) > 999) {
          task.count = 999;
        }
        else if (typeof task.count === 'undefined') {
          task.count = null;
        }
        
        if (typeof task.progress !== 'undefined' &&
            task.progress !== null) {
          if ((task.progress.indexOf('%') !== -1 || (parseInt(task.progress) > 1 && parseInt(task.progress) <= 100))) {
            task.progress = parseInt(task.progress) / 100;
          }
          else {
            task.progress = 0;
          }
        }
        if (typeof task.constraint === 'string' &&
            task.constraint !== null) {
            task.constraint = this.constraintMap[task.constraint];   
        }
        else if (typeof task.constraint === 'object' &&
                 task.constraint.type in this.constraintMap) {
          task.constraint.type = this.constraintMap[task.constraint.type];         
        }
        else if (typeof task.constraint === 'object') {
          let found = false;
          for (const key of Object.keys(this.constraintMap)) {
            const value = this.constraintMap[key];
            if (value === task.constraint.type) {
              found = true;
              break;
            }
          }
          
          if (!found) {
            task.constraint = null;
          }         
        }
        
        if (task.starttime && !moment(task.starttime).isValid()) {
          task.starttime = null;
        }
        
        if (task.closetime && !moment(task.closetime).isValid()) {
          task.closetime = null;
        }
        
        if (task.startdate && typeof task.startdate !== 'string' &&
            !moment(task.startdate).isValid()) {
          task.startdate = null;
        }
        
        if (task.enddate && typeof task.enddate !== 'string' &&
            !moment(task.enddate).isValid()) {
          task.enddate = null;
        }
        
        if (/\d+\/\d+\/\d+/.test(task.startdate)) {
          task.startdate = `${task.startdate} GMT`;
        }
          
        if (task.startdate && typeof task.startdate === 'string' &&
            moment(task.startdate).format('h:mm:ss a') === '12:00:00 am') {
          if (!ISO_8601.test(task.startdate)) {
            let start = new Date(task.startdate);
            task.startdate = moment(this.toIsoString(start)).set({"hour": 9, "minute": 0}).format('YYYY-MM-DDTHH:mm:ss')
          }
          else {
            task.startdate = moment(task.startdate).set({"hour": 9, "minute": 0}).format('YYYY-MM-DDTHH:mm:ss');  
          }
        }
        
        if (task.enddate && typeof task.enddate === 'string' &&
            (moment(task.enddate).format('h:mm:ss a') === '12:00:00 am' ||
            new Date(task.enddate).getHours() === 0)) { 
          if (/\d+\/\d+\/\d+/.test(task.enddate)) {
            task.enddate = `${task.enddate} GMT`;
          }
          
          if (!ISO_8601.test(task.enddate)) {
            let end = new Date(task.enddate);
            task.enddate = moment(this.toIsoString(end)).set({"hour": 17, "minute": 0}).format('YYYY-MM-DDTHH:mm:ss')
          }
          else {
            task.enddate = moment(task.enddate).set({"hour": 17, "minute": 0}).format('YYYY-MM-DDTHH:mm:ss')
          }
        }
        
        if (typeof task.enddate !== 'string' && typeof task.startdate !== 'string' &&
            task.enddate !== null && task.startdate !== null &&
            task.startdate > task.enddate) {
          if (task.duration) {
            const result = convertDisplayToDuration(task.duration, this.durationConversionOpts);
            task.enddate = task.startdate + result.value;
          }
          else {
            task.startdate = task.enddate;
          }    
        }
        
        if ((this.mode === 'STAFF' || this.mode === 'STAFF_GENERIC') &&
            this.filteredStaffTypeOptions().filter(f => f.value === task.type).length === 0) {
          task.type = null;
        }
        
        if (task.payamount && task.payamount.includes(',')) {
          task.payamount = task.payamount.replace(/,/g, ''); // remove all commas from pay values
        }
        
        if (this.mode === 'STAFF' || this.mode === 'STAFF_GENERIC') {
          if (task.paycurrency == null || this.optionCurrency.find(f => f.value == task.paycurrency) == null) {
            // find a partial match
            const curr = task.paycurrency ? this.optionCurrency.find(f => task.paycurrency.startsWith(f.value)) : null;
            if (curr) {
              task.paycurrency = curr.value;
            }
            else {
              // set the default value
              task.paycurrency = this.currencyCode;
            }
          }
        }
        
        if (this.mode === 'COMPANY' &&
            this.filteredCompanyTypeOptions().filter(f => f.value === task.type && task.type !== 'Primary').length === 0) {
          task.type = null;
        }
        
        if (task.websites && (task.websites.match(/\n/g) || []).length > 0) {
          const sites = task.websites.split('\n');
          task.websites = sites.join(', ');
        }
        
        if (task.image && !task.image.startsWith('data:')) {
          task.image = null;
        }
        
        this.taskMap[task.id] = task;
      }
      
      this.rowData = cloneDeep(this.taskData);
      
      this.detectOrientationChange();
      this.expandParents();
    },
    toIsoString(date) {
      const pad = function(num) {
          return (num < 10 ? '0' : '') + num;
      };
    
      return date.getFullYear() +
          '-' + pad(date.getMonth() + 1) +
          '-' + pad(date.getDate());
    },
    onGridReady(/* params */) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.prepareTasks();
    },
    expandParents() {
      this.$nextTick(() => {
        this.state.dataUpdating = true;
        
        this.gridApi.forEachNode((node/*, index */) => {
          //if(node.group) {
            if (typeof node.data !== 'undefined') {
              node.setSelected(node.data.id === this.selectedItem);
            }
            node.setExpanded(true);
          //}
        })
        setTimeout(() => {
          this.state.dataUpdating = false;
        }, 500);
      });
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.gridApi) {
          this.gridApi.sizeColumnsToFit();  
        }
      }, 100);
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    }, 
    async confirmDeleteOk() {
      this.confirmDeleteShow = false;
      this.deletePreviewRow(this.selected);
      
      if (this.mode === 'STAFF') {
        const inUse = await getStaffEmailInUse(this.rowData);
        for (const staff of inUse) {
          if (staff.email.length > 0) {
            const found = this.rowData.filter(l => l.email && l.email.toLowerCase() === staff.email.toLowerCase());
            if (found.length !== 0) {
              if (this.showingExists) {
                found[0].emailErrorMessageHidden = 'staff.error.email_is_used';
              }
              else {
                found[0].emailErrorMessage = 'staff.error.email_is_used';
              }
            }
            else {
              if (this.showingExists) {
                delete found[0].emailErrorMessageHidden;
              }
              else {
                delete found[0].emailErrorMessage;
              }
            }
          }
        }
        
        // check for duplicates in the list
        for (const staff of this.rowData) {
          if (staff.emailErrorMessage === 'staff.error.email_duplicate') {
            if (this.showingExists) {
              this.$set(staff, 'emailErrorMessageHidden', null);
            }
            else {
              this.$set(staff, 'emailErrorMessage', null);
            }
          }
          
          if (staff.email) {
            const instances = this.rowData.filter(l => l.email && l.email.toLowerCase() === staff.email.toLowerCase());
            if (instances.length > 1) {
              for (const inst of instances) {
                if (this.showingExists) {
                  inst.emailErrorMessageHidden = 'staff.error.email_duplicate';
                }
                else {
                  inst.emailErrorMessage = 'staff.error.email_duplicate';
                }
              }
            }
          }
        }
      }
      else if (this.mode === 'TAG') {
        // check for duplicates in the list
        for (const tag of this.rowData) {
          if (tag.nameErrorMessage === 'error.not_unique_key') {
            if (this.showingExists) {
              this.$set(tag, 'nameErrorMessageHidden', null);
            }
            else {
              this.$set(tag, 'nameErrorMessage', null);
            }
          }
          
          if (tag.name) {
            const instances = this.rowData.filter(l => l.name && l.name.toLowerCase() === tag.name.toLowerCase());
            if (instances.length > 1) {
              for (const inst of instances) {
                if (this.showingExists) {
                  inst.nameErrorMessageHidden = 'error.not_unique_key';
                }
                else {
                  inst.nameErrorMessage = 'error.not_unique_key';
                }
              }
            }
          }
        }
        
        const inUse = await getTagInUse(this.rowData);
        for (const tag of inUse) {
          if (tag.name.length > 0) {
            const found = this.rowData.findIndex(l => l.name && l.name.toLowerCase().trim() === tag.name.toLowerCase());
            if (found !== -1) {
              if (this.showingExists) {
                this.$set(this.rowData[found], 'nameErrorMessageHidden', 'error.not_unique_key');
              }
              else {
                this.$set(this.rowData[found], 'nameErrorMessage', 'error.not_unique_key');
              }
            }
          }
        }
        
      }
      
      this.$emit('dataChanged', { data: this.rowData });
      this.expandParents();
    },
    deletePreviewRow(tasks) {
      for (const task of tasks) {
        // delete child tasks first
        const childTasks = this.rowData.filter(t => t.parent === task.id);
        this.deletePreviewRow(childTasks);
        for (var idx = 0; idx < this.rowData.length; idx++) {
          if (this.rowData[idx].id === task.id) {
            this.rowData.splice(idx, 1);
            break;
          }
        }
      }
    },
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
    },
    dateTimeDurationValueChanged(nodes, colId, newValue) {
      let trigger = TRIGGERS.DURATION;
      if (colId == 'startdate') {
        trigger = TRIGGERS.START_DATE;
      } else if (colId == 'enddate') {
        trigger = TRIGGERS.CLOSE_DATE;
      } else if (colId == 'constraintType') {
        trigger = TRIGGERS.CONSTRAINT_TYPE;
      } else if (colId == 'constraintTime') {
        trigger = TRIGGERS.CONSTRAINT_DATE;
      } else if (colId == 'autoScheduling') {
        trigger = TRIGGERS.TASK_SCHEDULE_MODE;
      }
      
      const projectScheduleFromStart = this.scheduleMode != null? this.scheduleMode == 'ASAP' : true;
      const dCalculationTasks = this.durationCalculationTasks;
      dCalculationTasks.splice(0, dCalculationTasks.length);

      const pendingUpdateTasks = this.durationCalculationPendingUpdateTasks;
      pendingUpdateTasks.splice(0, pendingUpdateTasks.length);
      
      for (const node of nodes) {
        const nodeData = node.data;
        let newDateStr = null;
        let newTimeStr = null;
        let newConstraintType = null;
        let newConstraintDateStr = null;
        let newAutoScheduling = null;
        if (trigger == TRIGGERS.START_DATE || trigger == TRIGGERS.CLOSE_DATE) {
          const newDateTime = newValue != null? moment.utc(newValue) : null;
          if (newDateTime != null) {
            newDateStr = newDateTime.format('YYYY-MM-DD');
            newTimeStr = newDateTime.format('HH:mm');
          }
        } else if (trigger == TRIGGERS.CONSTRAINT_TYPE || trigger == TRIGGERS.CONSTRAINT_DATE) {
          if (newValue != null) {
            newConstraintType = newValue.type;
            newConstraintDateStr = newValue.time != null? moment.utc(newValue.time).format('YYYY-MM-DD') : null;
          }
        } else if (trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
          if (newValue != null) {
            newAutoScheduling = newValue;
          }
        }
        const startDateTime = nodeData.startdate != null? moment.utc(nodeData.startdate) : null;
        const closeDateTime = nodeData.enddate != null? moment.utc(nodeData.enddate) : null;
        const constraintDateTime = nodeData.constraint && nodeData.constraint.time != null? moment.utc(nodeData.constraint.time) : null;
        dCalculationTasks.push({
          uuId: nodeData.id
          , name: nodeData.name
          , startDateStr: startDateTime != null? startDateTime.format('YYYY-MM-DD') : null
          , startTimeStr: startDateTime != null? startDateTime.format('HH:mm') : null
          , closeDateStr: closeDateTime != null? closeDateTime.format('YYYY-MM-DD') : null
          , closeTimeStr: closeDateTime != null? closeDateTime.format('HH:mm') : null
          , durationDisplay: trigger == TRIGGERS.DURATION? newValue : nodeData.duration
          , constraintType: nodeData.constraint && nodeData.constraint.type != null? nodeData.constraint.type : null
          , constraintDateStr: constraintDateTime != null? constraintDateTime.format('YYYY-MM-DD') : null
          , newDateStr, newTimeStr
          , trigger: trigger
          , taskAutoScheduleMode: nodeData.autoScheduling != null? nodeData.autoScheduling : true
          , projectScheduleFromStart
          , lockDuration: nodeData.lockDuration != null? nodeData.lockDuration : false
          , projectStartDateStr: this.project != null? this.project.startDateStr : null
          , projectCloseDateStr: this.project != null? this.project.closeDateStr : null
          , newConstraintType, newConstraintDateStr
          , newAutoScheduling
        });
      }
      this.initDurationCalculation();
    },
    initDurationCalculation({ useExistingValues=false, skipOutOfProjectDateCheck=null, autoMoveForNonWorkingDay=false } = {}) {
      if (useExistingValues) {
        //Used when calendarChange event is triggered. Pass along the 'session' skipOutOfProjectDateCheck state before resuming the session.
        if (skipOutOfProjectDateCheck != null) {
          this.durationCalculation.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
        }
        //Start calculation
        this.durationCalculationShow = true;
        return;
      }
      if (this.durationCalculationTasks.length == 0) {
        return; 
      }

      const currentTask = this.durationCalculationTasks.shift();
      this.durationCalculationPendingUpdateTasks.push({ 
        uuId: currentTask.uuId
        , taskAutoScheduleMode: currentTask.newAutoScheduling != null? currentTask.newAutoScheduling : currentTask.taskAutoScheduleMode
      });
      const dc = this.durationCalculation;
      dc.trigger = currentTask.trigger;
      dc.taskName = currentTask.name;
      dc.calendar = this.project != null && this.project.locationId != null? this.calendar : null;
      dc.oldDateStr = null;
      dc.oldTimeStr = null;
      if (currentTask.trigger == TRIGGERS.START_DATE || currentTask.trigger == TRIGGERS.START_TIME) {
        dc.startDateStr = currentTask.newDateStr;
        dc.startTimeStr = currentTask.newTimeStr;
        dc.oldDateStr = currentTask.startDateStr;
        dc.oldTimeStr = currentTask.startTimetr;
      } else {
        dc.startDateStr = currentTask.startDateStr;
        dc.startTimeStr = currentTask.startTimeStr;
      }
      if (currentTask.trigger == TRIGGERS.CLOSE_DATE || currentTask.trigger == TRIGGERS.CLOSE_TIME) {
        dc.closeDateStr = currentTask.newDateStr;
        dc.closeTimeStr = currentTask.newTimeStr;
        dc.oldDateStr = currentTask.closeDateStr;
        dc.oldTimeStr = currentTask.closeTimeStr;
      } else {
        dc.closeDateStr = currentTask.closeDateStr;
        dc.closeTimeStr = currentTask.closeTimeStr;
      }
      dc.durationDisplay = currentTask.durationDisplay;
      dc.lockDuration = currentTask.lockDuration;
      
      if (currentTask.trigger == TRIGGERS.CONSTRAINT_TYPE || currentTask.trigger == TRIGGERS.CONSTRAINT_DATE) {
        dc.constraintType = currentTask.newConstraintType;
        dc.constraintDateStr = currentTask.newConstraintDateStr;
        dc.oldConstraintType = currentTask.constraintType;
        dc.oldConstraintDateStr = currentTask.constraintDateStr;
      } else {
        dc.constraintType = currentTask.constraintType;
        dc.constraintDateStr = currentTask.constraintDateStr;
      }

      dc.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck != null? skipOutOfProjectDateCheck : false;
      dc.defaultActionForNonWorkPrompt = autoMoveForNonWorkingDay? 'move': null;

      dc.taskAutoScheduleMode = currentTask.taskAutoScheduleMode;
      dc.projectScheduleFromStart = currentTask.projectScheduleFromStart;
      dc.projectStartDateStr = currentTask.projectStartDateStr;
      dc.projectCloseDateStr = currentTask.projectCloseDateStr;

      //Start calculation
      //$nextTick is required to wait for the on-going ui rendering to be done.
      this.$nextTick(() => {
        this.durationCalculationShow = true;
      });
    },
    durationCalculationOk({ skip=false, startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr, taskAutoScheduleMode=null }) {
      const pendingUpdateTask = this.durationCalculationPendingUpdateTasks[this.durationCalculationPendingUpdateTasks.length - 1];
      if (skip) {
       pendingUpdateTask.skip = true;
      } else {
        if (startDateStr != null) {
          const startDateTime = moment.utc(startDateStr, 'YYYY-MM-DD');
          if (startTimeStr != null) {
            const token = startTimeStr.split(':');
            startDateTime.hour(token[0]).minute(token[1]);
          }
          pendingUpdateTask.startTime = startDateTime.valueOf();
        } else {
          pendingUpdateTask.startTime = null;
        }

        if (closeDateStr != null) {
          const closeDateTime = moment.utc(closeDateStr, 'YYYY-MM-DD');
          if (closeTimeStr != null) {
            const token = closeTimeStr.split(':');
            closeDateTime.hour(token[0]).minute(token[1]);
          }
          pendingUpdateTask.closeTime = closeDateTime.valueOf();
        } else {
          pendingUpdateTask.closeTime = null;
        }

        if (taskAutoScheduleMode != null) {
          pendingUpdateTask.taskAutoScheduleMode = taskAutoScheduleMode;
        }

        if (durationDisplay != null) {
          const { value } = convertDisplayToDuration(durationDisplay, this.durationConversionOpts);
          const { unit } = analyzeDurationAUM(durationDisplay);
          pendingUpdateTask.durationAUM = unit;
          pendingUpdateTask.duration = value;
        } else if (pendingUpdateTask.taskAutoScheduleMode) {
          pendingUpdateTask.durationAUM = 'D';
          pendingUpdateTask.duration = this.durationConversionOpts.hourPerDay * 60; // 1 day = 480 minutes
        } else {
          pendingUpdateTask.duration = null; 
        }

        pendingUpdateTask.constraintType = constraintType;
        if (constraintDateStr != null) {
          pendingUpdateTask.constraintTime = moment.utc(constraintDateStr, 'YYYY-MM-DD').valueOf();
        } else {
          pendingUpdateTask.constraintTime = null;
        }

        //Remove extra properties which are not needed in task update request call later.
        pendingUpdateTask.autoScheduling = pendingUpdateTask.taskAutoScheduleMode;
        delete pendingUpdateTask.taskAutoScheduleMode;
      }
      
      if (this.durationCalculationTasks.length > 0) {
        this.initDurationCalculation({ skipOutOfProjectDateCheck: true, autoMoveForNonWorkingDay: true });
      } else {
        //call taskservice to update the tasks and hide grid overlay and progress dialog.
        if (this.durationCalculationPendingUpdateTasks.length > 0) {
          const tasks = this.durationCalculationPendingUpdateTasks.filter(i => i.skip == null || i.skip != true);
          for (const task of tasks) {
            const node = this.gridApi.getRowNode(task.uuId);
            node.data.duration = convertDurationToDisplay(task.duration, 'D', this.durationConversionOpts);
            node.data.startdate = task.startTime;
            node.data.enddate = task.closeTime;
            node.data.constraint = { time: task.constraintTime, type: task.constraintType };
          }
          this.gridApi.refreshCells();
          this.$emit('dataChanged', { data: this.rowData});
        }
        
      }
    },
    durationCalculationCancel() {
      this.inProgressShow = false;
    },
    async durationCalculationCalendarChange({ toAddExceptions, toUpdateExceptions, skipOutOfProjectDateCheck }) {
      //1. Call calendar service (API) to add or update the exceptions
      //2. Update the calendar object
      //3. Call calcDateTimeDuration() to restart calculation with updated calendar object.
      //4. Reload latest calendar from backend for future usage.

      let hasError = false;
      const calendar = this.calendar;
      const locationId = this.project.locationId;
      const baseDateTime = moment.utc().hour(0).minute(0).second(0).millisecond(0);
      
      if (toUpdateExceptions != null && toUpdateExceptions.length > 0) {
        const _toUpdateExceptions = cloneDeep(toUpdateExceptions);
        _toUpdateExceptions.forEach(i => {
          delete i.calendar;
        });
        await calendarService.update(_toUpdateExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
            return;
          }

          //Update the calendar object with the change
          if (calendar.Leave == null) {
            calendar.Leave = [];
          }
          for (let i = 0, len = _toUpdateExceptions.length; i < len; i++) {
            const curException = _toUpdateExceptions[i];
            //When exception type is Working, add it to calendar.Working
            if (curException.type == 'Working') {
              if (calendar.Working == null) {
                calendar.Working = [];
              }
              calendar.Working.push(cloneDeep(curException));
            }

            //Remove old leave exception if exception type is 'Working'. Otherwise, update the old leave exception.
            const idx = calendar.Leave.findIndex(j => j.uuId === curException.uuId);
            if (idx != -1) {
              if (curException.type == 'Working') {
                calendar.Leave.splice(idx, 1);
              } else {
                calendar.Leave[idx] = cloneDeep(curException);
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
        });
      }

      //Stop proceed further when failed to update calendar exception.
      if (hasError) {
        this.durationCalculationCancel();
        return;
      }

      if (toAddExceptions != null && toAddExceptions.length > 0) {
        //Convert startHour and endHour from '##:##' to number in millisecond.
        for (let i = 0, len = toAddExceptions.length; i < len; i++) {
          const currentException = toAddExceptions[i];
          if (currentException.startHour != null) {
            currentException.startHour = moment.utc(currentException.startHour, 'HH:mm').diff(baseDateTime);
          }
          if (currentException.endHour != null) {
            currentException.endHour = moment.utc(currentException.endHour, 'HH:mm').diff(baseDateTime);
          }
        }

        await calendarService.create(toAddExceptions, locationId)
        .then(response => {
          if (response == null || 207 == response.status) {
            hasError = true;
          } else { //
            //Fill the uuId to exception and add the newly created exception to calendar object
            const list = response.data[response.data.jobCase];
            for (let i = 0, len = list.length; i < len; i++) {
              const curItem = list[i];
              if (curItem && curItem.uuId != null) {
                const curException =  toAddExceptions[i];
                curException.uuId = curItem.uuId;
                curException.startHour = baseDateTime.clone().add(curException.startHour, 'millisecond').format('HH:mm')
                curException.endHour = baseDateTime.clone().add(curException.endHour, 'millisecond').format('HH:mm')
                if (calendar[curException.type] == null) {
                  calendar[curException.type] = [];
                }
                calendar[curException.type].push(cloneDeep(curException));
              } else {
                hasError = true;
                break;
              }
            }
          }
        })
        .catch(() => {
          hasError = true;
        });
      }

      if (hasError) {
        this.durationCalculationCancel();
      } else {
        this.initDurationCalculation({ useExistingValues: true, skipOutOfProjectDateCheck })
      }

      //Refresh calendar object
      if (locationId != null) {
        this.$nextTick(() => {
          this.locationCalendar(locationId);
        });
      }
    },
    getPath(data, path) {
      path = `/${data.name}${path}`;
      if (data.parent) {
        const res = this.rowData.find(r => r.id === data.parent);
        if (res) {
          return this.getPath(res, path);
        }
      }
      return path;
    },
    checkExists() {
      let changed = false;
      this.showingExists = !this.showingExists;
      
      for (const d of this.rowData) {
        if (this.mode === 'TASKS') {
          const f = this.existingData.filter(e => e.name === d.name);
          if (f.length !== 0) {
            for (const candidate of f) {
              let path = candidate.taskPath.split('\n');
              path.splice(0, 1); // remove project name
              path = `/${path.join('/')}`;
              const thisPath = this.getPath(d, '');
              if (thisPath === path) {
                if (this.showingExists) {
                  d.uuId = candidate.uuId;
                }
                else {
                  delete d.uuId;
                }
                changed = true;
                break;
              }
            }
          }
        }
        else if (this.mode === 'STAFF') {
          const f = this.existingData.filter(e => typeof d.email !== 'undefined' && e.email.toLowerCase() === d.email.toLowerCase());
          if (f.length !== 0) {
            if (this.showingExists) {
              d.uuId = f[0].uuId;
              d.emailErrorMessageHidden = d.emailErrorMessage;
              delete d.emailErrorMessage;
            }
            else {
              delete d.uuId;
              d.emailErrorMessage = d.emailErrorMessageHidden;
              delete d.emailErrorMessageHidden;
            }
            changed = true;
          }
        }
        else {
          const filtered = this.existingData.filter(e => e.name === d.name);
          if (filtered.length !== 0 &&
              (this.mode !== 'DEPARTMENT' || (this.mode === 'DEPARTMENT' && !filtered[0].type))) {
            if (this.showingExists) {
              d.uuId = filtered[0].uuId;
            }
            else {
              delete d.uuId;
            }
            
            if (this.mode === 'COMPANY' &&
                filtered[0].type === 'Primary') {
              d.type = filtered[0].type;   
            }
            else if (this.mode === 'TAG') {
              if (this.showingExists) {
                d.nameErrorMessageHidden = d.nameErrorMessage;
                delete d.nameErrorMessage;
              }
              else {
                d.nameErrorMessage = d.nameErrorMessageHidden;
                delete d.nameErrorMessageHidden;
              }
            }
            changed = true;
          }
        }
      }
      
      if (changed) {
        this.gridApi.redrawRows();
        this.$emit('dataChanged', { data: this.rowData });
      }
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ag-theme-balham .ag-row-selected {
  background-color: transparent;
  background-color: var(--ag-selected-row-background-color, transparent);
}

.total-tasks {
  position: absolute;
  right: 20px;
}
</style>
