var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-import-mapper", class: { dBlock: _vm.show } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.getTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "xl",
            visible: _vm.show,
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.canPreview,
                        size: "sm",
                        variant: "success",
                      },
                      on: { click: _vm.modalPreview },
                    },
                    [
                      _vm._v(" Preview "),
                      _vm.preparing
                        ? _c(
                            "div",
                            { staticClass: "d-inline-block mh-50" },
                            [
                              _c("b-spinner", {
                                attrs: { small: "", label: "Loading..." },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.modalCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "import-controls" },
            [
              _c("b-form-select", {
                staticClass: "mr-2",
                attrs: { options: _vm.sheetNames() },
                model: {
                  value: _vm.currentSheet,
                  callback: function ($$v) {
                    _vm.currentSheet = $$v
                  },
                  expression: "currentSheet",
                },
              }),
              _c(
                "span",
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-action",
                      attrs: { id: "IMPORT_MAPPER_SHOW_SETTING" },
                      on: { click: _vm.showSettings },
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "cog" } })],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "IMPORT_MAPPER_SHOW_SETTING",
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.settings")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-action",
                      attrs: { id: "IMPORT_MAPPER_AUTO_SELECT" },
                      on: {
                        click: function ($event) {
                          return _vm.autoSelect(false, true)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "border-all"] },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "IMPORT_MAPPER_AUTO_SELECT",
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.auto_select")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-action",
                      attrs: { id: "IMPORT_MAPPER_SELECT_NONE" },
                      on: {
                        click: function ($event) {
                          return _vm.selectNone()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "border-none"] },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "IMPORT_MAPPER_SELECT_NONE",
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.select_none")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            staticClass: "mt-2 ag-theme-balham mapper-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "myGrid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              enableRangeSelection: true,
              enableRangeHandle: true,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              headerHeight: 90,
              rowData: _vm.rowData,
              suppressContextMenu: true,
              suppressMultiSort: "",
            },
            on: {
              "grid-ready": _vm.onGridReady,
              "range-selection-changed":
                _vm.handleDebouncedRangeSelectionChanged,
            },
          }),
          _vm.submitting
            ? _c(
                "div",
                { staticClass: "submit-overlay" },
                [
                  _c("b-spinner", {
                    staticClass: "spinner",
                    attrs: { variant: "primary", label: "Loading..." },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ImportDocSettingsModal", {
        attrs: {
          show: _vm.showSettingsModal,
          settings: _vm.settings,
          properties: _vm.properties,
          title: _vm.importSettingsTitle,
        },
        on: {
          "update:show": function ($event) {
            _vm.showSettingsModal = $event
          },
          success: _vm.settingsChanged,
        },
      }),
      _c("GanttImportMapperPreviewDialog", {
        attrs: {
          source: _vm.items,
          fields: _vm.previewFields,
          customFields: _vm.customFields,
          staffs: _vm.staffs,
          skills: _vm.skills,
          resources: _vm.resources,
          projectId: _vm.projectId,
          requiredFields: _vm.requiredFields,
          existingData: _vm.existingData,
          mode: _vm.mode,
          properties: _vm.properties,
          show: _vm.state.previewShow,
        },
        on: {
          "modal-ok": _vm.modalPreviewOk,
          "modal-cancel": _vm.modalPreviewCancel,
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "previewShow", $event)
          },
          stages: _vm.onStages,
        },
      }),
      _c("GanttErrorDialog", {
        attrs: { msg: _vm.errorMsg, show: _vm.state.errorDialogShow },
        on: { "modal-ok": _vm.errorDialogOk },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.select_parent"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.selectParentOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectParentShow,
            callback: function ($$v) {
              _vm.selectParentShow = $$v
            },
            expression: "selectParentShow",
          },
        },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("task.select_parent_field", [_vm.treeTitle()]))
            ),
          ]),
          _c("TaskTree", {
            attrs: {
              height: "250px",
              noEdit: true,
              taskData: _vm.taskData,
              selectedItem: _vm.editParentTask,
              title: _vm.$t("task.summary_tasks"),
              staffs: _vm.staffs,
              skills: _vm.skills,
              resources: _vm.resources,
              multiple: false,
            },
            on: { selected: _vm.onTaskSelected },
          }),
          _c(
            "b-form-checkbox",
            {
              staticClass: "parent-apply-to-all",
              model: {
                value: _vm.applyToAll,
                callback: function ($$v) {
                  _vm.applyToAll = $$v
                },
                expression: "applyToAll",
              },
            },
            [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.select_owner"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.selectOwnerOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectOwnerShow,
            callback: function ($$v) {
              _vm.selectOwnerShow = $$v
            },
            expression: "selectOwnerShow",
          },
        },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("task.select_owner_field", [_vm.treeTitle()]))
            ),
          ]),
          _c("TaskTree", {
            attrs: {
              height: "250px",
              noEdit: true,
              taskData: _vm.taskData,
              selectedItem: _vm.editOwnerTask,
              title: _vm.$t("task.title_selector"),
              staffs: _vm.staffs,
              skills: _vm.skills,
              resources: _vm.resources,
              multiple: false,
            },
            on: { selected: _vm.onOwnerSelected },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.apply_settings"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.applySettingsOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.applySettingsShow,
            callback: function ($$v) {
              _vm.applySettingsShow = $$v
            },
            expression: "applySettingsShow",
          },
        },
        [_c("label", [_vm._v(_vm._s(_vm.$t("task.apply_settings_body")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }