<template>
  <div class="widget-box">
    <b-modal size="xl" :title="title" 
        id="preview-modal"
        class="container"
        content-class="shadow"
        no-close-on-backdrop
        
        :visible="show" 
        @hidden="modalCancel"
      >
      
      <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <TaskTree 
        :projectId="projectId" 
        height="calc(100vh - 300px)" 
        :taskData="source" 
        :mode="mode" 
        :enableDelete="true" 
        :preview="true" 
        :fields="fields" 
        :customFields="customFields"
        :requiredFields="requiredFields"
        :staffs="staffs" 
        :skills="skills" 
        :resources="resources" 
        :multiple="true" 
        :existingData="existingData"
        :title="projectId !== null ? $t('task.title_selector') : mode === 'STAFF' ? $t('staff.field.lastName') : $t('document.name')" 
        :properties="properties"
        @stages="onStages" 
        @columnChange="onColumnChange" 
        @dataChanged="onDataChanged" 
        @error="onError"/>

      <template v-slot:modal-footer="{  }">
        <b-button size="sm" variant="success" :disabled="disableImport" @click="modalConfirm">Import</b-button>
        <b-button size="sm" variant="danger" @click="modalCancel">Cancel</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('task.warning')"
        v-model="showWarningPrompt"
        @ok="warningPromptOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <label>{{$t('task.import_warning', [warningTaskCount])}}</label>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import TaskTree from '@/components/Task/TaskTree';
  import { checkRequiredField } from '@/helpers';
  import { customFieldValidateAndType } from'@/helpers/custom-fields'
  import currencies from '@/views/management/script/currencies';
  
  export default {
    components: {
      TaskTree
    },
    props: {
      show:   { type: Boolean, default: false },
      title:  { type: String, default: "Import Document Preview" },
      source: { type: Array, default: () => [] },
      fields: { type: Array, default: () => [] },
      customFields: { type: Array, default: () => [] },
      staffs: { type: Object, default: () => {} },
      skills: { type: Object, default: () => {} },
      resources: { type: Object, default: () => {} },
      projectId: { type: String, default: null },
      requiredFields: { type: Array, default: () => [] },
      mode: { type: String, default: 'TASKS' },
      properties: { type: Array, default: null },          // Properties the user can choose
      existingData: { type: Array, default: null }
    },
    data: function() {
      return {
        alertMsg: null,
        columns: [],
        items: [],
        disableImport: false,
        showWarningPrompt: false,
        warningTaskCount: 0,
        optionPriority: [],
        optionCurrency: []
      }
    },
    computed: {
      showError() {
        return this.alertMsg != null;
      }
    },
    created() {
      this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.GanttPriorityEnum != null) {
              const obj = propertyList.GanttPriorityEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                if (obj[c] < 0) {
                  continue
                }
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.optionPriority.splice(0, this.optionPriority.length, ...list)
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.optionCurrency.splice(0, this.optionCurrency.length, ...list)
            }
          } 
        }
      }).catch(e => {
        this.alertMsg = e;
      });
    },
    watch: {
      show(newValue) {
        if (newValue) {
          this.items = this.source;
          this.columns = this.fields;
          this.checkRequiredFields();
          if (this.projectId !== null || this.mode === 'TASKS') {
            this.columns.push('type');
            this.columns.push('templateUuid');
          }
          
          for (const field of this.requiredFields) {
            this.columns.push(field);
          }
          
          if (this.columns.includes('summary_desc')) {
            this.columns.push('desc');
          }
          
          if (!this.columns.includes('name')) {
            this.columns.push('name');
          }
        }
      },
      source(newValue) {
        this.disableImport = newValue.length === 0;
      }
    },
    methods: {
      onStages(stages) {
        this.$emit('stages', stages);
      },
      modalCancel() {
        this.$emit("modal-cancel");
      },
      onColumnChange(columns) {
        this.columns = [];
        for (const column of columns) {
          if (column.colId === 'ag-Grid-AutoColumn') {
            this.columns.push('name');
          }
          else {
            this.columns.push(column.colId);
          }
        }
        this.columns.push('type');
        this.columns.push('templateUuid');
        if (!this.columns.includes('name')) {
          this.columns.push('name');
        }
      },
      countTasks() {
        let totalCount = 0;
        for (const item of this.items) {
          // update the count
          if (item.count) {
            totalCount += parseInt(item.count);
          }
          else {
            totalCount++;
          }
        }
        return totalCount;
      },
      modalConfirm() {
        let totalCount = this.countTasks();
        
        if (totalCount > 1000) {
          this.warningTaskCount = totalCount;
          this.showWarningPrompt = true;
        }
        else {
          this.$emit("modal-ok", { columns: this.columns, items: this.items });
        }
      },
      async onDataChanged({data, colId}) {
        this.items = data;
        this.disableImport = this.countTasks() === 0;
        
        if (colId && !this.columns.includes(colId)) {
          this.columns.push(colId);
        }
        this.checkRequiredFields();
      },
      checkRequiredFields() {
        this.alertMsg = null;
        if (this.requiredFields.length !== 0) {
          this.disableImport = false;
          if (this.items.length === 0) {
            this.disableImport = true;
            for (const field of this.requiredFields) {
              if (!this.fields.find(f => field === f)) {
                this.alertMsg = this.$t('missing_field', [field]);
                return;
              }
            }
          }
          
          for (const item of this.items) {
            for (const field of this.requiredFields) {
              if (checkRequiredField(field, item, this.mode, { currencies: this.optionCurrency, priorities: this.optionPriority })) {
                this.disableImport = true;
              }
            }
            
            for (const customField of Object.keys(item)) {
              // if this is not a custom field it will return false
              if (customFieldValidateAndType(item[customField], customField, this.customFields)) {
                this.disableImport = true;
                break; // the custom field has an invalid value
              }
            }
            
            if (this.mode === 'TAG' &&
                item.nameErrorMessage) {
              this.disableImport = true;
              break;   
            }
          }
        }
      },
      onError(e) {
        this.alertMsg = e;
      },
      dismissAlert() {
        this.alertMsg = null;
      },
      warningPromptOk() {
        this.$emit("modal-ok", { columns: this.columns, items: this.items });
      }
    }
  }
</script>
<style scoped>
.widget-box {
  height: calc(100vh - 77px);
}

</style>
<style lang="scss">
.display-overflow {
  overflow-y: auto;
}
.action {
  margin-left: 4px;
  padding: 2px 6px;
  margin-bottom: 4px;

  &, &[disabled]:active {
    background-color: transparent !important;
    border: none !important;
  }
  
  &:focus,&:active:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>

