

<template>
  <div class="plain-tooltip">
    <p>{{ params.value }}</p>
  </div>
</template>

<script>
import Vue from 'vue';
import cColors from "@/_chartColors";

export default Vue.extend({
  name: 'PlainTooltip',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    var data = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex)
      .data;
    data.color = cColors.getThemeColor('surface-bg');
    this.setState(data);
  },
  mounted() {
    this.$el.style['background-color'] = this.color;
  },
  methods: {
    setState(obj) {
      Object.assign(this, obj);
    }
  }
})
</script>

<style lang="scss" scoped>
.plain-tooltip {
  position: absolute;
  border: 1px solid var(--black);
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.plain-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.plain-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

</style>