

<template>
  <div>
    {{ label }}
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ArrayCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    this.value = this.params && 
      this.params.value && 
      this.params.value.length > 0 &&
      this.params.labelField ? this.params.value.map(v => { return v[this.params.labelField] }).join(', ') : null;
  },
  computed: {
    label() {
      return this.value;
    }
  }
})
</script>
<style lang="scss" scoped>

</style>